import { useEffect, useState } from "react";
import LoadingSpinner from "../../layout/loading-spinner";
import {
  ArrowLeft,
  ArrowRight,
  Button,
  Card,
  Input,
  Modal,
  TerminalIcon,
} from "../../lib";
import {
  terminalsCreateAndRegister,
  trsmsUpload,
} from "../../utils/api/trsm-apis";

type Props = {
  isFileUploadModalOpen: boolean;
  recordsFromFile: { [key: string]: any };
  toggleModal: () => void;
  type: "terminals" | "trsms";
};

const TerminalUploadModal = ({
  isFileUploadModalOpen,
  recordsFromFile,
  toggleModal,
  type,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState<number[]>([]); //Object.keys(recordsFromFile)
  const [page, setPage] = useState(pages[0]);

  const close = () => {
    setIsLoading(false);
    toggleModal();
  };

  useEffect(() => {
    setPages(Object.keys(recordsFromFile).map((i) => Number(i)));
    setPage(Number(Object.keys(recordsFromFile)[0]));
    setIsLoading(false);
  }, [Object.keys(recordsFromFile).length]);

  // pages ['7', '11']
  // page '7'

  const incrementPage = () => {
    if (pages.indexOf(page) < pages.length - 1)
      setPage((prevState) => pages[pages.indexOf(page) + 1]);
  };
  const decrementPage = () => {
    if (pages.indexOf(page) > 0)
      setPage((prevState) => pages[pages.indexOf(page) - 1]);
  };

  return (
    <Modal
      close={close}
      isOpen={isFileUploadModalOpen}
      title={"Terminal File Upload "}
      subTitle={"File results !"}
      icon={TerminalIcon}
    >
      <LoadingSpinner
        isLoading={isLoading && Boolean(Object.keys(recordsFromFile))}
      />
      {Object.keys(recordsFromFile).length ? (
        <>
          <div className="flex flex-row justify-stretch space-x-2 h-full">
            <button
              onClick={() => decrementPage()}
              className="flex-initial bg-transparent hover:bg-gray-100 rounded-md"
            >
              <ArrowLeft size={28} color="text-black" />
            </button>
            <div className="flex flex-col justify-center w-full">
              <div className="flex-1 flex items-center">
                <div className="flex flex-col w-full">
                  <Input
                    label={"Merchant"}
                    value={recordsFromFile[page]?.name}
                    name={"merchant_name"}
                    onChange={(e) => {}}
                    isDisabled
                  />
                  <label
                    className={"font-bold mb-1 text-black text-sm lg:text-base"}
                  >
                    TRSMS
                  </label>
                  {recordsFromFile[page]?.trsms?.map(
                    (trsm: any, index: number) => (
                      <Card
                        classNames={
                          "w-full my-2 px-4 py-3 flex justify-between items-center font-bold hover:bg-gray-100 cursor-pointer"
                        }
                        key={index}
                      >
                        <span className="block">{trsm}</span>
                      </Card>
                    )
                  )}
                </div>
              </div>
              <Button
                className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
                colorScheme="blue"
                onClick={() => {
                  type == "trsms"
                    ? terminalsCreateAndRegister(recordsFromFile)
                    : trsmsUpload(recordsFromFile);
                }}
                label={"Submit All"}
              />
            </div>
            <button
              onClick={() => incrementPage()}
              className="flex-initial bg-transparent hover:bg-gray-100 rounded-md"
            >
              <ArrowRight size={28} color="text-black" />
            </button>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default TerminalUploadModal;
