import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../layout/loading-spinner";
import { Button, Errors, Input } from "../lib/forms";
import { getUserByHashApi, updatePasswordApi } from "../utils/api/auth-apis";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../assets/payment-schemes.svg";
import { usePromiseTracker } from "react-promise-tracker";
import {
  ClassValidationError,
  GeneralError,
  HttpStatus,
  NiceError,
} from "../types/errors";

const UpdatePassword = () => {
  const { promiseInProgress: isLoading } = usePromiseTracker();

  const [email, setEmail] = useState("");
  const [searchParams, _] = useSearchParams();
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [resetPasswordErrors, setResetPasswordErrors] = useState<any>({});
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const updatePassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      setResetPasswordErrors({});
      e.preventDefault();
      if (password != retypePassword)
        throw { general: ["Password does not match!."] };
      await updatePasswordApi({
        password_hash: searchParams.get("p_hash") as string,
        password,
        recaptchaToken,
      });
      navigate("/");
    } catch (e: any) {
      setResetPasswordErrors(e);
    }
  };
  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);

  const getUserByHash = async () => {
    try {
      const { email } = await getUserByHashApi({
        passwordHash: searchParams.get("p_hash") as string,
      });
      email ? setIsInvited(true) : setIsInvited(false);
      setEmail(email);
    } catch (error: NiceError | GeneralError | ClassValidationError | any) {
      if (
        error.code === 4018 ||
        error.statusCode === HttpStatus.INTERNAL_SERVER_ERROR ||
        error.code === 2
      )
        navigate("/");
    }
  };

  useEffect(() => {
    getUserByHash();
  }, []);

  return (
    <div
      style={{ padding: "20px" }}
      className="flex flex-row h-full w-full sm:justify-center relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full justify-center flex-grow max-w-md m-auto ">
          <form className=" flex flex-1 flex-col justify-center">
            <span className="text-5xl font-bold">
              {t("resetPasswordLabel")}
            </span>
            <Errors errors={resetPasswordErrors?.general || []} />
            <div className="py-1 flex flex-col gap-1">
              <label
                className={
                  i18n.language == "ar"
                    ? "text-sm lg:text-base font-bold mb-1 text-black text-right"
                    : "text-sm lg:text-base font-bold mb-1 text-black "
                }
                htmlFor={email}
              >
                {t("email") as string}
              </label>
              <div
                className={
                  "rounded-md border py-2 px-3 border-gray-200 font-bold text-sm lg:text-base focus:border-blue-700 focus:ring-blue-700 focus:ring-1 "
                }
              >
                {email ? email : "No User Was Found"}
              </div>
            </div>

            <Input
              name="password"
              value={password}
              type="password"
              label={t("password") as string}
              errors={resetPasswordErrors?.password || []}
              onChange={(e) => {
                setPassword(e);
              }}
            />
            <Input
              name="retype-password"
              value={retypePassword}
              type="password"
              label={t("retypePassword") as string}
              errors={resetPasswordErrors?.retypePassword || []}
              onChange={(e) => {
                setRetypePassword(e);
              }}
            />
            <Button
              className={
                "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full py-2 px-4 mt-4"
              }
              label={t("resetPasswordLabel") as string}
              onClick={updatePassword}
              colorScheme={"black"}
              isRounded={false}
            />
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};
export default UpdatePassword;
