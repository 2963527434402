import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ParsedFileData, RequiredHeader } from "../../types/batch";
import Content from "../../layout/content";
import { Button, Select } from "../../lib";
import { CreateBatchUploadApi } from "../../utils/api/batch-apis";
import { requiredHeaders } from "./data";
import BackButton from "../../components/shared/back-button";

interface MappedBatchPayload {
  [key: string]: {
    fileHeader: string;
    data: string[];
  };
}

interface Error {
  [key: string]: { [key: string]: boolean };
}

const BatchUploadMapping: FC<{}> = () => {
  const {
    state: { file_name, dataArray },
  } = useLocation() as {
    state: {
      file_name: string;
      dataArray: ParsedFileData[];
    };
  };

  const { t } = useTranslation();

  const [errors, setErrors] = useState<{
    [key: string]: {
      [key: string]: boolean;
    };
  }>({});

  const [mappedPayload, setMappedPayload] = useState<MappedBatchPayload>({});

  const mapHeader = (header: RequiredHeader, value: string) => {
    setErrors({});
    setMappedPayload((prevState: MappedBatchPayload) => {
      return {
        ...prevState,
        [header.key]: {
          fileHeader: value,
          data: dataArray.find((i) => i.headerFromFile == value)?.data || [],
        },
      };
    });
  };

  const saveCurrentStateAndContinue = async () => {
    await CreateBatchUploadApi({
      mappedBatchPayload: mappedPayload,
      file_name,
    }).then((result) => navigate("../uploads/workspace/" + result?.id));
  };

  const assignErrors = ({
    key,
    index,
    value,
  }: {
    key: string;
    index: string;
    value: boolean;
  }) => {
    if (
      errors[index]?.[key] === undefined ||
      (errors[index]?.[key] === false && value === true) ||
      (errors[index]?.[key] === true && value === false)
    ) {
      setErrors((prevState: any) => ({
        ...prevState,
        [index]: { ...prevState[index], [key]: value },
      }));
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    dataArray.forEach((header) => {
      requiredHeaders.forEach((requiredHeader) => {
        if (requiredHeader.autoMappedHeaders.includes(header.headerFromFile)) {
          mapHeader(requiredHeader, header.headerFromFile);
        }
      });
    });
  }, []);

  return (
    //   <Content hasBackButton title={t("connectFields") as string}>
    <>
      <div className="flex gap-3 my-2 items-center mx-2 lg:mx-0">
        <BackButton />
        <div className="text-sm font-bold">{t("goBack") as string} </div>
      </div>
      <div className="flex flex-col bg-white  xl:px-0 my-4 rounded-lg ">
        <div className="py-4 sm:py-6  rounded-lg">
          <div className="w-full flex items-center justify-center text-xs sm:text-base bg-white">
            <div className=" overflow-x-scroll w-full justify-center items-center ">
              <table className="w-full">
                <tbody>
                  <tr className="overflow-x-scroll ">
                    {requiredHeaders.map((requiredHeader, index) => (
                      <td key={index} className="align-top p-0 ">
                        <table className="w-full">
                          <thead>
                            <tr>
                              <td className="p-2 pt-0  text-neutral-500 truncate">
                                {t(requiredHeader.header) as string}
                                {!requiredHeader?.is_optional ? " * " : ""}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="sm:p-2 p-1 w-1/3 ">
                                <div className="flex flex-col w-24 sm:w-full">
                                  <Select
                                    onChange={(e) =>
                                      mapHeader(requiredHeader, e)
                                    }
                                    selectContainerClasses={"w-full"}
                                    selectClasses={
                                      " focus:ring-white focus:outline-none "
                                    }
                                    data={dataArray}
                                    value={
                                      mappedPayload[requiredHeader.key]
                                        ?.fileHeader
                                    }
                                    valueKey={"headerFromFile"}
                                    displayKey={"headerFromFile"}
                                    name={"HeaderFromFile"}
                                    label=""
                                  />
                                </div>
                              </td>
                            </tr>
                            <tr className="h-2 bg-gray-200 ">
                              <td></td>
                            </tr>
                            <tr className="w-full ">
                              {mappedPayload[requiredHeader.key]?.data?.map(
                                (v, i) => {
                                  let isValid = requiredHeader.regex.test(
                                    v.trim()
                                  );
                                  assignErrors({
                                    key: requiredHeader.key,
                                    index: String(i),
                                    value: isValid,
                                  });

                                  return (
                                    <td
                                      className={`flex flex-auto p-2 my-1 truncate
                                ${
                                  Object?.keys?.(
                                    errors[String(i)] || {}
                                  ).filter(
                                    (header) => !errors[String(i)][header]
                                  ).length > 0
                                    ? ` 
                                    ${
                                      errors[String(i)]?.[requiredHeader.key]
                                        ? "border-b-2  border-b-red-200"
                                        : " font-bold text-red-700 border-b-2 border-b-red-600"
                                    }
                                    `
                                    : "border-b-2 border-b-gray-200"
                                }
                                 
                                `}
                                      key={i}
                                    >
                                      {v ? v : <div>Missing !</div>}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-white p-2 my-4 rounded-lg ">
        <div className="flex justify-end gap-2">
          <Button
            colorScheme={"black"}
            isRounded={false}
            label={t("next") as string}
            onClick={saveCurrentStateAndContinue}
            className={
              "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full max-w-[120px]"
            }
            isDisabled={
              Object.keys(mappedPayload).some(
                (headerKey) =>
                  mappedPayload[headerKey].fileHeader == "" &&
                  !requiredHeaders.find(
                    (requiredHeader) => requiredHeader.key === headerKey
                  )?.is_optional
              ) ||
              Object.keys(errors).every((column) =>
                Object.keys(errors[column]).some(
                  (value) => errors[column][value] == false
                )
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default BatchUploadMapping;
