import { FC, SetStateAction, useEffect, useState } from "react";
import { TransactionsFilters } from "../components/filters/transactions-filters";
import QueryBuilder from "../components/query-builder/query-builder";
import DownloadTransactionsButton from "../components/shared/download-transactions-btn";
import ListTransactions from "../components/transactions/list";
import Content from "../layout/content";
import {
  usePreservedDateRange,
  usePreservedState,
} from "../custom-hooks/UsePreservedState";
import { dateRange } from "../components/query-builder/date-picker";
import { useTranslation } from "react-i18next";

type Props = {};

const Transactions: FC<Props> = ({}: Props) => {
  const [refreshList, setRefreshList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("All");
  const [transactions, setTransactions] = useState<any[]>([]);
  const [dateRange, setDateRange] = usePreservedDateRange<dateRange>(
    {},
    "@transactionsDateRange"
  );
  const [queryParams, setQueryParams] = usePreservedState<any>(
    {},
    "@transactionsQueryParams"
  );

  useEffect(() => {}, [refreshList]);
  return (
    <Content
      title={t("transactions")}
      isLoading={isLoading}
      tabs={TransactionsFilters}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
      queryBuilder={
        <QueryBuilder
          type="transactions"
          refreshList={refreshList}
          setRefreshList={setRefreshList}
          setDateRange={
            setDateRange as React.Dispatch<SetStateAction<dateRange>>
          }
          dateRange={dateRange as dateRange}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      }
      actionBtns={
        <DownloadTransactionsButton
          transactions={transactions}
          classNames={
            "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm h-full rounded-full"
          }
        />
      }
    >
      <ListTransactions
        refresh={refreshList}
        setIsLoading={setIsLoading}
        activeTab={activeTab}
        setTransactionsForDownload={setTransactions}
        dateRange={dateRange as dateRange}
        queryParams={queryParams}
      />
    </Content>
  );
};

export default Transactions;
