import LogRocket from "logrocket";
import {
  FC,
  useState,
  createContext,
  Context,
  ReactNode,
  useEffect,
} from "react";
import SwitchClient from "../components/modal/switch-client";
import { applyTheme } from "../themes";
import baseTheme from "../themes/base";
import Storage from "../utils/storage";

interface Client {
  id: number;
  name: string;
  logo: string;
  vendor_id: number;
  platform: string;
  primary_color: string;
  secondary_color: string;
  export_data_type: string;
}

interface Admin {
  id: string | null;
  name: string;
  mobile: string;
  email: string;
  created_at: Date | string;
  clients: Client[];
  hashedId?: string;
  platformAccess: string[];
}

export type UserContextType = {
  admin: Admin;
  hasToken: () => boolean;
  addToken: (accessToken: string, refreshToken: string) => void;
  getUser: () => Admin;
  logout: Function;
  toggleModal: () => void;
  getToken: () => {};
  refreshAccess: boolean;
};

type Props = {
  children: ReactNode;
};

const UserContext: Context<UserContextType | null> =
  createContext<UserContextType | null>(null);

const initialAdminState: Admin = {
  id: null,
  name: "",
  mobile: "",
  email: "",
  created_at: "",
  clients: [],
  platformAccess: [],
};

export const UserContextProvider: FC<Props> = ({ children }: Props) => {
  const [refreshAccess, setRefreshAccess] = useState(false);
  const [showSelectClientModal, setShowSelectClientModal] = useState(false);
  const [admin, setAdmin] = useState<Admin>(initialAdminState);

  const toggleModal = () =>
    setShowSelectClientModal((prevState: boolean) => !prevState);

  const getToken = () => {
    return {
      accessToken: Storage.get("accessToken") || "",
      refreshToken: Storage.get("refreshToken") || "",
    };
  };

  const getUser = () => {
    const admin = Storage.get("admin");
    if (!admin) {
      let token = Storage.get("accessToken");
      if (token) {
        let admin = decodeJWT(token);
        setAdmin(admin);
        return admin;
      }
      setAdmin(initialAdminState);
      // logout();
    }
    if (admin) {
      startIntercom(admin);
    }
    setAdmin(admin);
    return admin;
  };

  const startLogRocket = () => {
    if (process.env.REACT_APP_LOG_ROCKET_KEY) {
      const admin = Storage.get("admin");
      if (admin) {
        const currentClient: any = admin.clients.find(
          (i: any) => i.id == Storage.get("clientId")
        );
        LogRocket.identify(admin.id as any, {
          name: admin.name,
          email: admin.email,
          ...(currentClient?.id ? { client_id: currentClient.id } : {}),
          ...(currentClient?.id ? { client_name: currentClient.name } : {}),
        });
      }
    }
  };

  const hasToken = (): boolean => {
    let { accessToken } = getToken();
    startLogRocket();
    return accessToken ? true : false;
  };

  const addToken = (accessToken: string, refreshToken: string) => {
    let admin = decodeJWT(accessToken);
    Storage.set("accessToken", accessToken);
    Storage.set("refreshToken", refreshToken);
    Storage.set("admin", admin);
    startIntercom(admin);
    setActiveClient(getUser()?.clients[0]?.id);
  };

  const setActiveClient = (clientId: string) => {
    Storage.set("clientId", clientId);
    setRefreshAccess((prevState: boolean) => !prevState);
  };

  const startIntercom = (admin: any) => {
    (window as any).Intercom?.("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.REACT_APP_INTERCOM_KEY as string,
      name: admin.name,
      email: admin.email,
      created_at: new Date(admin.created_at).getTime(),
      user_hash: admin.hashedId,
      user_id: admin.id as string,
    });
  };

  const decodeJWT = (jwt: string) => {
    let token = jwt.split(".")[1];
    if (!token) return { accessToken: jwt };
    let base64 = token.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload)?.data;
  };

  const switchClient = (clientId: number) => {
    const clients = [
      ...admin.clients.filter((i: Client) => i.id == clientId),
      ...admin.clients.filter((i: Client) => i.id != clientId),
    ];
    Storage.set("admin", { ...admin, clients });
    Storage.set("clientId", clientId);
    setRefreshAccess((prevState) => !prevState);
    toggleModal();
  };

  const logout = () => {
    Storage.remove("accessToken");
    Storage.remove("refreshToken");
    Storage.remove("clientId");
    Storage.remove("admin");
    (window as any).Intercom?.("shutdown");
    (window as any).Intercom?.("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: process.env.REACT_APP_INTERCOM_KEY as string,
    });
    setRefreshAccess((prevState) => !prevState);
  };

  useEffect(() => {
    let admin = getUser();
    const client = admin?.clients
      ? admin?.clients?.filter?.(
          (i: Client) => i.id == Storage.get("clientId")
        )?.[0]
      : {
          primary_color: baseTheme["--theme-primary"],
          secondary_color: baseTheme["--theme-secondary"],
        };
    applyTheme({
      "--theme-primary": client?.primary_color
        ? client?.primary_color
        : baseTheme["--theme-primary"],
      "--theme-secondary": client?.secondary_color
        ? client?.secondary_color
        : baseTheme["--theme-secondary"],
    });
  }, [refreshAccess]);

  return (
    <UserContext.Provider
      value={{
        admin,
        hasToken,
        addToken,
        getUser,
        logout,
        toggleModal,
        getToken,
        refreshAccess,
      }}
    >
      {children}
      <SwitchClient
        isOpen={showSelectClientModal}
        toggleModal={toggleModal}
        clients={admin?.clients || []}
        selectClient={switchClient}
      />
    </UserContext.Provider>
  );
};

export default UserContext;
