import { useState, FC } from "react";
import Content from "../layout/content";
import ListAdmins from "../components/admins/list";
import CreateAdminModal, { NewAdmin } from "../components/modal/manage-admins";
import { AdminsFilters } from "../components/filters/admins-filters";
import { Button } from "../lib";
import { useTranslation } from "react-i18next";

type Props = {};

export const initialAdminState: NewAdmin = {
  name: "",
  mobile: "",
  email: "",
  type: "",
  bank_id: "",
};

const Admins: FC<Props> = ({ }: Props) => {
  const [newAdmin, setNewAdmin] = useState(initialAdminState);
  const [refreshList, setRefreshList] = useState(false);
  const [isCreateAdminModalOpen, setIsCreateAdminModalOpen] = useState(false);
  const { t } = useTranslation();
  const toggleCreateAdminModal = () => {
    setIsCreateAdminModalOpen((prev) => !prev);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  return (
    <>
      <Content
        tabs={AdminsFilters}
        title={t("admins")}
        isLoading={isLoading}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        // queryBuilder={<QueryBuilder type={"admins"} />}
        actionBtns={
          <Button
            className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
            onClick={() => toggleCreateAdminModal()}
            label={t("addNewAdmin") as string}
            colorScheme="skyblue"
            isRounded={true}
          />
        }
      >
        
        <ListAdmins
          limit={5}
          setIsLoading={setIsLoading}
          refresh={refreshList}
          activeTab={activeTab}
        />
        <CreateAdminModal
          toggleModal={toggleCreateAdminModal}
          isOpen={isCreateAdminModalOpen}
          newAdmin={newAdmin}
          setNewAdmin={setNewAdmin}
          setRefresh={setRefreshList}
        />
      </Content>
    </>
  );
};

export default Admins;
