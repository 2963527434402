import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}
type props = {
  options: any[];
  selected: {
    key: string;
    display: string;
    operator: string;
    select?: any[];
  };
  setSelected: React.Dispatch<
    React.SetStateAction<
      | {
          key: string;
          display: string;
          operator: string;
          select?: any[] | undefined;
        }
      | undefined
    >
  >;
  onChange: () => any;
};

export default function ListBox({
  options,
  selected,
  setSelected,
  onChange,
}: props) {
  const { t } = useTranslation();
  return (
    <Listbox
      value={selected}
      onChange={(param) => {
        setSelected(param);
        onChange();
      }}
    >
      {({ open }) => (
        <>
          <div className="relative flex justify-between">
            <Listbox.Button className="relative w-40 cursor-default h-full rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 sm:text-sm">
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center justify-between pr-2 w-full px-2">
                {selected.display ? selected.display : t("select")}
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option, index: number) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-blue-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "p-1 block  w-full"
                            )}
                          >
                            {option.display}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
