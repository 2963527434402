import { Filters } from ".";

export const TerminalsFilters: Filters[] = [
  {
    header: "All",
    key: "all",
    value: "all",
  },
  {
    header: "Device Connected",
    key: "user_id",
    value: "",
  },
  {
    header: "Device Not Connected",
    key: "device_id",
    value: "",
  },
];
