import React, { FC, LegacyRef, PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { mergeClassNames } from "../utils";

export interface CardType {
  classNames?: string;
  to: string;
}

export const CardV2: FC<PropsWithChildren<CardType>> = ({
  classNames = "",
  children,
  to,
}) => {
  return (
    <Link className="p-0 h-0" to={to}>
      {/* className={mergeClassNames(" p-2 ", classNames)}> */}
      {children}
    </Link>
  );
};
