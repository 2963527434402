import { FC, useEffect } from "react";
import Routes from "./utils/router";
import { HashRouter } from "react-router-dom";

import { UserContextProvider } from "./contexts/UserContext";

import { useTranslation } from "react-i18next";
import LogRocket from "logrocket";

const App: FC = ({}) => {
  const { i18n } = useTranslation();
  (window as any).Intercom?.("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: process.env.REACT_APP_INTERCOM_KEY as string,
  });
  document.body.dir = i18n.dir();

  useEffect(() => {
    if (process.env.REACT_APP_LOG_ROCKET_KEY)
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY);
  }, []);

  return (
    <>
      <HashRouter>
        <UserContextProvider>
          <Routes />
        </UserContextProvider>
      </HashRouter>
    </>
  );
};

export default App;
