import { FC, useEffect, useState } from "react";
import { Button, Card, EmptyState, Paginator } from "../../lib";
import {
  listWebhooksApi,
  WebhookListInterface,
} from "../../utils/api/webhooks-apis";
import { EventPreview } from "./event-preview";
import { WebhookEvent, WebhookMethod, WebhookURL } from "../../pages/Webhooks";
import { t } from "i18next";

type Props = {
  setIsLoading?: any;
  toggleModal?: any;
  setWebhook?: any;
  refresh?: any
};

const ListWebhooks: FC<Props> = ({ setIsLoading, refresh , toggleModal, setWebhook }: Props) => {
  const [selectedWebhook, setSelectedWebhook] = useState({});
  const [webhooks, setWebhooks] = useState<WebhookListInterface[]>([]);
  const [pages, setPages] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);

  const listWebhooks = async (page = 1) => {
    try {
      setIsLoading(true);
      let response = await listWebhooksApi({ page });
      setPages(response.pages);
      setWebhooks(response.webhooks);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    listWebhooks();
  }, [refresh]);

  return (
    <>
    {webhooks.length ? 
      <div className="flex flex-col md:flex-row md:items-start gap-4">
        <div className="md:w-1/2">
        <div className="grid grid-cols-6 gap-2 pb-1 px-2 capitalize md:font-bold">
          <span className="col-span-2 px-4">event</span>
          <span className="col-span-1  xl:px-4">method</span>
          <span className="col-span-3 px-4">url</span>
        </div>
          {webhooks.map((webhook, index) => {
            return (
             
              <div
                className={"w-full grid grid-cols-6 items-center mb-2 py-2 text-xs border rounded-md hover:bg-gray-100 cursor-pointer transition-all " + (index === currentIndex && "bg-gray-100")}
                key={index}
                onClick={() => {
                  setCurrentIndex(index)
                  setSelectedWebhook(webhook)
                } 
                }
              >
                <div className="col-span-2  px-4">
                  <WebhookEvent event={webhook.event.name} />
                </div>
                <div className="col-span-1  xl:px-4">
                                      <WebhookMethod method={webhook.method} />
                </div>
                <div className="col-span-3  px-4 ">
                    <WebhookURL url={webhook.url} />
                </div>
              </div>
             
              
            );
          })}
        </div>
        <div className="md:w-1/2">
          {selectedWebhook && <EventPreview webhook={selectedWebhook} setWebhook={setWebhook} toggleModal={toggleModal} />}
        </div>
      </div>
      : <EmptyState title={t("itemNotFound")} /> }
      {pages && pages > 1 ? (
        <div className="w-full flex justify-center items-center ">
          <Paginator
            pages={pages}
            selectPage={(page: number) => listWebhooks(page)}
          />
        </div>
      ) : null}
    </>
  );
};

export default ListWebhooks;