import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "../layout/content";
import ViewTransaction from "../components/transactions/view";
import { useTranslation } from "react-i18next";
type Props = {};

const Transaction: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  return (
    <Content title={t("transaction")} hasBackButton isLoading={isLoading}>
      <ViewTransaction setIsLoading={setIsLoading} id={id as string} />
    </Content>
  );
};

export default Transaction;
