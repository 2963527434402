import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../layout/loading-spinner";
import { Button, Errors, Input, Select } from "../../lib";
import { AppStore } from "../../lib/icons/app-store";
import { Modal } from "../../lib/modal";
import { App, SDKVersion } from "../apps/view";
import { createAppApi, updateAppApi } from "../../utils/api/apps-api";

type Props = {
  toggleModal: any;
  isOpen: any;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  sdks: any[];
  app: App;
  setApp: React.Dispatch<React.SetStateAction<App>>;
};

export const PackageTypes = [
  { value: "app", display: "Full App" },
  { value: "sdk", display: "SDK" },
];

const ManageAppModal = ({
  toggleModal,
  isOpen,
  setRefresh,
  app,
  setApp,
  sdks,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [createAppErrors, setCreateAppErrors] = useState<any>({});

  const close = () => {
    setCreateAppErrors({});
    toggleModal();
  };

  const { t, i18n } = useTranslation();

  const handleCreateApp = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await createAppApi({
        name: app.name,
        app_name: app.app_name,
      });
      setRefresh((prev) => !prev);
      setIsLoading(false);
      close();
    } catch (e: any) {
      e.error ? setCreateAppErrors(e) : void 0;
      e.message ? setCreateAppErrors(e.message) : void 0;
      setIsLoading(false);
    }
  };

  const handleUpdateApp = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await updateAppApi(app);
      setIsLoading(false);
      close();
    } catch (e: any) {
      e.error ? setCreateAppErrors(e) : void 0;
      e.message ? setCreateAppErrors(e.message) : void 0;
      setIsLoading(false);
    }
  };

  const updateValue = (val: string, key: string) => {
    setApp((prevState: App) => ({
      ...prevState,
      [key]: val,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("apps")}
      subTitle={app?.id ? t("editApp") : t("createNewApp")}
      close={close}
      icon={AppStore}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <form className="flex flex-col gap-2">
        <Errors
          errors={
            createAppErrors.error
              ? [
                  i18n.language == "ar"
                    ? createAppErrors.error.arabic
                    : createAppErrors.error.english,
                ]
              : []
          }
        />

        <>
          <Input
            type="text"
            label={t("packageName") as string}
            value={app?.name}
            name="packageName"
            onChange={(e) => updateValue(e, "name")}
            errors={createAppErrors?.name || []}
            placeholder="e.g. example.package.io"
          />
          <Input
            type="text"
            label={t("appName") as string}
            value={app?.app_name}
            name="app_name"
            onChange={(e) => updateValue(e, "app_name")}
            errors={createAppErrors?.app_name || []}
          />
        </>
        <Button
          colorScheme={"black"}
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
          isRounded={false}
          onClick={app.id ? handleUpdateApp : handleCreateApp}
          label={t("save") as string}
        />
      </form>
    </Modal>
  );
};

export default ManageAppModal;
