import { FC, useContext, useEffect, useState } from "react";

import { listTRSMsApi } from "../../utils/api/trsm-apis";
import LoadingSpinner from "../../layout/loading-spinner";
import { EmptyState, GroupedList, TRSMLIstItem } from "../../lib";
import { useTranslation } from "react-i18next";

type Props = {
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  setIsLoading: any;
  isLoading: boolean;
  limit?: number;
};

const ListTRSMs: FC<Props> = ({
  setIsLoading,
  refresh,
  isLoading,
  limit = 10,
}: Props) => {
  const [trsms, setTRSMs] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const { t } = useTranslation();

  const fetchTRSMs = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTRSMsApi({
        page,
        limit,
      });
      setPages(response.pages);
      setTRSMs(response.trsms);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTRSMs();
  }, [refresh]);

  return trsms.length ? (
    <div>
      <LoadingSpinner isLoading={isLoading} />
      <GroupedList
        pages={{
          selectPage: (page) => fetchTRSMs(page),
          total: pages,
        }}
        data={trsms}
        groupBy={{
          key: "created_at",
          isDate: true,
        }}
        component={TRSMLIstItem}
        role={"client"}
      />
    </div>
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListTRSMs;
