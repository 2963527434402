import { Filters } from ".";

export const MerchantsFilters: Filters[] = [
  {
    header: "All",
    key: "all",
    value: "all",
  },
  {
    header: "Active",
    key: "is_active",
    value: "active",
  },
  {
    header: "Not Active",
    key: "is_active",
    value: "deactive",
  },
];
