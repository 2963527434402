import { trackPromise } from "react-promise-tracker";
import Api, { List } from ".";

interface Users extends List {
  filter?: { [key: string]: any };
  merchant_id?: string;
  mobile?: string;
}

interface User {
  id: string;
}
export interface UserResponse {
  id: string;
  name: string;
  mobile: string;
  email: string;
  is_active: boolean;
  merchant_id: string;
  created_at: string;
}
interface NewUser {
  name: string;
  mobile: string;
  email: string;
  merchant_id: string;
}
export const listUsersApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchant_id,
  mobile,
}: Users) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "users/",
        params: {
          limit,
          page,
          ...filter,
          merchant_id,
          mobile,
        },
      })
    );
    return response.data as { pages: number; users: UserResponse[] };
  } catch (e: any) {
    throw e;
  }
};
export const findUserApi = async ({ id }: User) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "users/" + id,
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const createUserApi = async ({
  name,
  email,
  mobile,
  merchant_id,
}: NewUser) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "users/",
        data: {
          name,
          mobile,
          email: email.toLowerCase?.(),
          merchant_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const revokeUserAccess = async ({
  merchant_id,
  user_id,
}: {
  merchant_id: string;
  user_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "users/revoke",
        data: {
          merchant_id,
          user_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const findUserByMobileApi = async ({ mobile }: { mobile: string }) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "users/" + mobile,
      })
    );
    return response.data as {
      users: UserResponse[];
    };
  } catch (e) {
    throw e;
  }
};
