import axios, { AxiosRequestConfig } from "axios";
import Storage from "../storage";
import { refreshAccessTokenApi } from "./auth-apis";

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_BASEURL + "/clients/",
  headers: {
    "Content-Type": "application/json",
  },
});

const onboardingApi = axios.create({
  baseURL: "v1/onboarding/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const refreshApi = axios.create({
  baseURL: "v1/clients/",
  headers: {
    "Content-Type": "application/json",
  },
});

baseApi.interceptors.request.use(async (request: any) => {
  try {
    let token = (await Storage.get("accessToken")) || "";
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
      request.headers.client_id = Storage.get("clientId");
    }
    return request;
  } catch (e) {
    console.log({ e });
  }
});

baseApi.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (e: any) {
    console.log("from response ", { e });
    if (e?.response) throw e?.response?.data;
    throw e;
  }
);

onboardingApi.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (e: any) {
    if (e?.response) throw e?.response?.data;
    throw e;
  }
);

onboardingApi.interceptors.request.use(async (request: any) => {
  try {
    let token = (await Storage.get("accessToken")) || "";
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  } catch (e) {
    console.log({ e });
  }
});

const Api = async (config: AxiosRequestConfig) => {
  try {
    const response = await baseApi(config);
    return response;
  } catch (e: any) {
    if (e.statusCode === 401 && e.message == "Unauthorized") {
      // let refreshResponse = await refreshAccessToken();
      // Storage.set("accessToken", refreshResponse[0].access_token);
      let refreshResponses = await refreshAccessToken();
      Storage.set("accessToken", refreshResponses.accessToken);
      const response = await baseApi(config);
      return response;
    }
    console.log("api error", { e });
    throw e;
  }
};

export const OnboardingApi = async (config: AxiosRequestConfig) => {
  try {
    const response = await onboardingApi(config);
    return response;
  } catch (e: any) {
    if (e.statusCode === 401 && e.message == "Unauthorized") {
      let refreshResponses = await refreshAccessToken();
      Storage.set("accessToken", refreshResponses.accessToken);
      const response = await onboardingApi(config);
      return response;
    }
    throw e;
  }
};

function decodeJWT(jwt: string) {
  let token = jwt.split(".")[1];
  let base64 = token.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return { accessToken: jwt, ...JSON.parse(jsonPayload)?.data };
}

export default Api;
export interface List {
  page?: number;
  limit?: number;
}

export interface ApiParams {
  toggleLoading?: Function;
}
const refreshAccessToken = async () => {
  try {
    let refreshResponse = await refreshAccessTokenApi(
      Storage.get("refreshToken")
    );
    return refreshResponse;
  } catch (e: any) {
    window.location.href = `${window.location.origin}/`;
    Storage.remove("accessToken");
    Storage.remove("refreshToken");
    window.location.reload();
  }
};
