import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { BatchIcon } from "../icons";
import { CardV2 } from "../shared";

export interface MerchantListItemType {
  role: "admin" | "client";
  title?: string;
  onClick?: (item?: any) => void;
  item: any;
}

export const BatchListItem: FC<MerchantListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-3 w-full border-2 border-gray-300 shadow-sm rounded-md p-2">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <CardV2 to={"../uploads/workspace/" + item?.id}>
        <div className="flex flex-row items-center">
          <BatchIcon containerClassNames="pr-4" />
          <div className="flex flex-col flex-1">
            <span className="font-bold text-sm lg:text-base">
              {item?.file_name || "--"}
            </span>
            <span className="text-gray-600 text-xs">#{item?.id || ""}</span>
          </div>
          <StatusBadge is_completed={item?.is_completed} />
        </div>
      </CardV2>
    </div>
  );
};

const StatusBadge = ({ is_completed }: { is_completed: boolean }) => {
  const { t } = useTranslation();
  return is_completed ? (
    <div className=" p-1 text-xs rounded-md border-full bg-green-100 text-green-300">
      {t("completed") as string}
    </div>
  ) : (
    <div className=" p-1 text-xs rounded-md border-full bg-blue-100 text-blue-300">
      {t("draft") as string}
    </div>
  );
};
