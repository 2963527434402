import { FC, ReactNode, useContext } from "react";
import Header from "./header";
import { usePromiseTracker } from "react-promise-tracker";
import LoadingSpinner from "./loading-spinner";
import Navbar from "./navbar";

type Props = {
  children: ReactNode;
};

const Container: FC<Props> = ({ children }: Props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <>
      {/* <Sidebar /> */}
      <div className="w-full h-full bg-gray-200">
        <div className="top-0 z-20">
          <Header />
        </div>
        <div className="sticky top-0 z-20">
          <Navbar />
        </div>

        <div className="sm:w-full md:w-full lg:w-5/6 xl:w-4/5 mx-auto">
          <main className="relative">
            <LoadingSpinner isLoading={promiseInProgress} />
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default Container;
