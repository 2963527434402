import { FC, useEffect, useState } from "react";
import { Input, Button } from "../lib/forms";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Errors } from "../lib";
import LoadingSpinner from "../layout/loading-spinner";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../assets/payment-schemes.svg";
import { SingUpApi } from "../utils/api/onboarding-apis";
import { usePromiseTracker } from "react-promise-tracker";
import {
  countriesOptions,
  defaultCountriesOption,
} from "../utils/countriesOptions";

const SignUp: FC<{}> = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const invitedEmail = searchParams.get("email");
  const invitedPlatformId = searchParams.get("id");
  const invitedName = searchParams.get("name");
  const invitedPlatformName = searchParams.get("platformName");
  const [isInvited, setIsInvited] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (invitedPlatformId) {
      setIsInvited(true);
      setName(invitedName as string);
    }
  }, []);

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [signupErrors, setSingUpErrors] = useState<any>({});
  const { promiseInProgress: isLoading } = usePromiseTracker();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  function login() {
    navigate("/");
  }

  const signup = async (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    try {
      setSingUpErrors({});
      let response = await SingUpApi({
        email,
        name,
        mobile,
        password,
        recaptchaToken: "",
      }).then((response) => {
        navigate("/verify", {
          state: { receiver: response.receiver, admin_id: response.id },
        });
      });
    } catch (error: any) {
      if (error.message) {
        setSingUpErrors(error.message);
      } else setSingUpErrors(error);
    }
  };

  return (
    <div
      style={{ padding: "20px" }}
      className="sm:justify-center flex flex-row h-full w-full relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="flex flex-1 flex-col justify-center ">
            <span className="text-5xl font-bold">{t("signup")}</span>
            <div className="mb-4 mt-2">
              <Errors
                errors={
                  signupErrors.error
                    ? [
                        i18n.language == "ar"
                          ? signupErrors.error.arabic
                          : signupErrors.error.english,
                      ]
                    : []
                }
              />
            </div>
            <div className={`${isInvited ? "my-2" : "hidden"}`}>
              <div className="text-sm lg:text-base font-bold mb-1 text-black ">
                Email
              </div>
              <div className="font-bold ">{invitedEmail}</div>
            </div>
            <Input
              name="name"
              value={name}
              type="text"
              label={t("name")}
              errors={signupErrors?.name}
              onChange={setName}
            />
            <Input
              name="mobile"
              value={mobile}
              type="phone"
              defaultDropDownOption={defaultCountriesOption}
              label={t("mobile")}
              errors={signupErrors?.mobile}
              dropDownOptions={countriesOptions}
              dropDownValueKey="dial_code"
              dropDownDisplayKey="display_name"
              onChange={(value) => {
                setMobile(value);
              }}
            />
            <Input
              name="email"
              value={email}
              type="email"
              inputContainerClasses={`${isInvited ? "hidden" : ""}`}
              label={t("email")}
              errors={signupErrors?.email}
              onChange={setEmail}
            />
            <Input
              name="password"
              value={password}
              type="password"
              label={t("password")}
              errors={signupErrors?.password}
              onChange={setPassword}
            />

            <div id="test-signup-button">
              <Button
                className={
                  "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full py-2 px-4 mt-4"
                }
                label={t("createAccount") as string}
                onClick={signup}
                colorScheme="black"
                isRounded={false}
              />
            </div>
            <div className="pt-4 text-center flex justify-end w-full">
              <div className="max-w-200px">
                <Button
                  label={t("login") as string}
                  onClick={login}
                  className={
                    "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full py-2 px-4 mt-4 border border-black"
                  }
                  isRounded={false}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>

      {/* <div className="flex flex-col flex-1 max-w-2xl m-4 p-4">
        <div className="logo flex-initial">
          <Logo />
        </div>
        
        <div className="flex-initial p-4">
          <span>Logos</span>
        </div>
      </div> */}
    </div>
  );
};

export default SignUp;
