import React, { FC } from "react";
import { mergeClassNames } from "../utils";

export interface BadgeType {
  label: string;
  size?: "sm" | "lg";
  isRounded?: boolean;
  color?:
    | "gray"
    | "red"
    | "yellow"
    | "green"
    | "blue"
    | "indigo"
    | "purple"
    | "pink";
}

const colorSchemeClasses: { [key: string]: string } = {
  gray: "inline-flex items-center rounded font-medium bg-gray-100 text-gray-800",
  red: "inline-flex items-center rounded font-medium bg-red-100 text-red-800",
  yellow:
    "inline-flex items-center rounded font-medium bg-yellow-100 text-yellow-800",
  green:
    "inline-flex items-center rounded font-medium bg-green-100 text-green-800",
  blue: "inline-flex items-center rounded font-medium bg-blue-100 text-blue-800",
  indigo:
    "inline-flex items-center rounded font-medium bg-indigo-100 text-indigo-800",
  purple:
    "inline-flex items-center rounded font-medium bg-purple-100 text-purple-800",
  pink: "inline-flex items-center rounded font-medium bg-pink-100 text-pink-800",
};

const sizeClasses: { sm: string; lg: string } = {
  sm: "px-4 py-0 text-xs leading-none",
  lg: "lg:px-6 lg:py-0.5 lg:text-sm text-xs px-3 py-1",
};

export const Badge: FC<BadgeType> = ({
  label,
  color = "blue",
  isRounded,
  size = "lg",
}) => {
  return (
    <span
      className={mergeClassNames(
        colorSchemeClasses[color],
        sizeClasses[size],
        isRounded ? "rounded-full" : ""
      )}
    >
      {label}
    </span>
  );
};
