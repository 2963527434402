import LoadingSpinner from "../layout/loading-spinner";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../assets/payment-schemes.svg";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Button, Errors, Input } from "../lib";
import { useTranslation } from "react-i18next";
import { updateMobileApi } from "../utils/api/auth-apis";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation, useNavigate } from "react-router-dom";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { CountryCodes } from "../lib/utils/country-codes";

const countriesList = CountryCodes.map((i) => ({
  ...i,
  display_name: `${i.code}: ${i.dial_code}`,
}));

const UpdateMobile = () => {
  const { promiseInProgress: isLoading } = usePromiseTracker();
  const [mobile, setMobile] = useState("");
  const [updateMobileErrors, setUpdateMobileErrors] = useState<any>({});
  const { i18n, t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { state } = useLocation();
  const navigate = useNavigate();

  const onVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
  }, [executeRecaptcha]);

  const updateMobile = async (e: SyntheticEvent) => {
    e?.preventDefault();
    try {
      await trackPromise(executeRecaptcha?.() as Promise<string>).then(
        async (token: string) => {
          setUpdateMobileErrors({});
          let response = await updateMobileApi({
            mobile,
            admin_id: (state as { admin_id: string }).admin_id,
            recaptchaToken: token as string,
          });
          navigate("/verify", {
            state: { mobile: mobile, admin_id: response?.id },
          });
        }
      );
    } catch (error: any) {
      if (error?.message) setUpdateMobileErrors(error.message);
      else setUpdateMobileErrors(error);
    }
  };

  return (
    <div
      style={{ padding: "20px" }}
      className=" sm:justify-center flex flex-row h-full w-full relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center flex-initial">
          <Logo />
          <GoogleReCaptcha onVerify={onVerify} />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="flex flex-1 flex-col justify-center ">
            <span className="text-5xl font-bold">{t("updateMobile")}</span>
            <div className="mb-4 mt-2">
              <Errors
                errors={
                  updateMobileErrors?.error
                    ? [
                        i18n.language == "ar"
                          ? updateMobileErrors.error.arabic
                          : updateMobileErrors.error.english,
                      ]
                    : []
                }
              />
            </div>
            <Input
              name="mobile"
              value={mobile}
              type={"phone"}
              dropDownOptions={countriesList}
              dropDownDisplayKey="display_name"
              dropDownValueKey="dial_code"
              label={t("mobile")}
              onChange={setMobile}
              errors={updateMobileErrors?.mobile as []}
            />
            <Button
              className={
                "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full py-2 px-4 mt-4"
              }
              label={t("updateMobile") as string}
              colorScheme="black"
              isRounded={false}
              onClick={updateMobile}
            />
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};

export default UpdateMobile;
