import React, {
  ChangeEvent,
  ElementType,
  FC,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CountryCodes } from "../utils/country-codes";
import { Errors } from "./error";
import { Feedback } from "./feedback";

interface InputType {
  label?: string;
  name: string;
  value: string;
  dropDownOptions?: any[];
  dropDownDisplayKey?: string;
  dropDownValueKey?: string;
  onChange: (e: string, event?: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  isDisabled?: boolean;
  icon?: ElementType;
  type?: "text" | "email" | "search" | "password" | "phone" | "number";
  prefix?: string;
  inputContainerClasses?: string;
  inputLabelClasses?: string;
  inputClasses?: string;
  feedback?: {
    message: string;
    colorScheme: "green" | "red";
  };
  errors?: string[];
  length?: number;
  defaultDropDownOption?: any;
  pattern?: string;
}

export const Input: FC<InputType> = ({
  label,
  name,
  type = "text",
  value,
  placeholder,
  dropDownOptions = [],
  dropDownDisplayKey = "name",
  dropDownValueKey = "id",
  onChange,
  isDisabled = false,
  icon,
  prefix,
  feedback,
  inputClasses = "",
  inputLabelClasses = "",
  inputContainerClasses = "",
  errors = [],
  length,
  defaultDropDownOption,
  pattern,
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={"flex flex-col py-1 " + inputContainerClasses}>
      {label ? (
        <label
          className={
            i18n.language == "ar"
              ? "text-sm lg:text-base font-bold mb-1 text-black text-right" +
                inputLabelClasses
              : "text-sm lg:text-base font-bold mb-1 text-black " +
                inputLabelClasses
          }
          htmlFor={name}
        >
          {label}
        </label>
      ) : null}
      {dropDownOptions.length > 0 ? (
        <InputWSelect
          name={name}
          type={type}
          onChange={onChange}
          inputClasses={inputClasses}
          isDisabled={isDisabled}
          dropDownDisplayKey={dropDownDisplayKey}
          dropDownValueKey={dropDownValueKey}
          dropDownOptions={dropDownOptions}
          defaultDropDownOption={defaultDropDownOption}
        />
      ) : prefix ? (
        <InputWPrefix
          prefix={prefix}
          name={name}
          type={type}
          length={length}
          inputClasses={inputClasses}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          isDisabled={isDisabled}
        />
      ) : icon ? (
        <InputWIcon
          icon={icon}
          name={name}
          length={length}
          type={type}
          inputClasses={inputClasses}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          isDisabled={isDisabled}
        />
      ) : (
        <DefaultInput
          isDisabled={isDisabled}
          name={name}
          length={length}
          type={type}
          value={value}
          inputClasses={inputClasses}
          onChange={onChange}
          placeholder={placeholder}
          pattern={pattern}
        />
      )}
      {feedback?.message && (
        <div className="block">
          <Feedback
            message={feedback?.message}
            colorScheme={feedback?.colorScheme}
          />
        </div>
      )}
      <Errors errors={errors} />
    </div>
  );
};

const DefaultInput = ({
  name,
  type,
  value,
  onChange,
  placeholder,
  inputClasses,
  isDisabled,
  length,
  pattern,
}: Partial<InputType>) => {
  return (
    <input
      id={name}
      name={name}
      type={type}
      value={value}
      maxLength={length}
      disabled={isDisabled}
      onChange={(e) => onChange && onChange(e.target.value, e)}
      placeholder={placeholder}
      pattern={pattern}
      className={
        "rounded-md border-gray-200 font-bold text-sm lg:text-base focus:border-blue-700 focus:ring-blue-700 focus:ring-1 " +
        inputClasses
      }
    />
  );
};

const InputWPrefix = ({
  prefix,
  name,
  type,
  inputClasses,
  placeholder,
  onChange,
  value,
  isDisabled,
}: Partial<InputType>) => {
  return (
    <div className="mt-1 relative rounded-md shadow-sm z-0">
      <div className="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none border-r border-r-6 border-gray-300 font-bold">
        <span className="sm:pr-1 text-sm lg:text-base">{prefix} </span>
      </div>
      <input
        type={type}
        name={name}
        id={name}
        className={
          "block w-full pl-28 border-gray-200 rounded-md font-bold text-sm lg:text-base focus:border-blue-700 focus:ring-blue-700 focus:ring-1  " +
          inputClasses
        }
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value, e)}
        value={value}
        disabled={isDisabled}
      />
    </div>
  );
};

const InputWIcon = ({
  icon: Icon,
  name,
  type,
  inputClasses,
  placeholder,
  onChange,
  value,
  isDisabled,
}: Partial<InputType>) => {
  return (
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-1 flex items-center pointer-events-none">
        {Icon && <Icon size={16} color="fill-gray-300" />}
      </div>
      <input
        type={type}
        name={name}
        id={name}
        className={
          "block w-full pl-10 border-gray-300 rounded-md border-gray-200 rounded-md pl-1 font-bold text-sm lg:text-base focus:border-blue-700 focus:ring-blue-700 focus:ring-1 " +
          inputClasses
        }
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e.target.value, e)}
        value={value}
        disabled={isDisabled}
      />
    </div>
  );
};

const InputWSelect = ({
  name,
  type,
  onChange,
  dropDownDisplayKey = "name",
  dropDownOptions = [],
  dropDownValueKey = "id",
  defaultDropDownOption,
  inputClasses,
  isDisabled,
}: Partial<InputType>) => {
  const [dropDownValue, setDropDownValue] = useState(
    defaultDropDownOption || ""
  );
  const [value, setValue] = useState("");

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDropDownValue(e.target.value);
    onChange && onChange(e.target.value + value);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(dropDownValue + e.target.value);
  };

  return (
    <div className="relative rounded-md shadow-sm  border border-gray-200 text-sm lg:text-base focus-within:border-blue-700 focus-within:ring-1 focus-within:ring-blue-700">
      <div className="absolute inset-y-0 left-0 flex items-center border-r border-gray-300 ">
        <select
          className="border-0 w-18 p-2 rounded-l-md h-full border-gray-200 focus:ring-0 focus:outline-none font-bold "
          onChange={(e) => handleSelect(e)}
          value={dropDownValue}
        >
          {dropDownOptions?.map((item, index: number) => {
            return (
              <option value={item[dropDownValueKey]} key={index}>
                {item[dropDownDisplayKey]}
              </option>
            );
          })}
        </select>
      </div>
      <input
        type={type}
        name={name}
        id={name}
        className={
          "block w-full pl-28 sm:pl-28  border-gray-200 rounded-md p-2  focus:ring-0 focus:outline-none font-bold " +
          inputClasses
        }
        onChange={(e) => handleInputChange(e)}
        value={value}
        disabled={isDisabled}
      />
    </div>
  );
};
