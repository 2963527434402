import React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import { EmptyState, GroupedList, BatchListItem } from "../../lib";
import { GetBatchesApi } from "../../utils/api/batch-apis";

type Props = {
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  setIsLoading: (state: boolean) => void;
  activeTab?: string;
  limit?: number;
};

const ListBatches: FC<Props> = ({
  setIsLoading,
  refresh,
  setRefresh,
  activeTab,
  limit = 10,
}: Props) => {
  const [batches, setBatches] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const { refreshAccess } = useContext(UserContext) as UserContextType;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchBatches = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await GetBatchesApi({
        page,
        limit,
      });
      setPages(response.pages);
      setBatches(response.batches);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToBatch = (batch: any) => navigate("../" + batch.id);

  useEffect(() => {
    fetchBatches();
  }, [refresh, activeTab, refreshAccess]);

  return batches?.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchBatches(page),
      }}
      role={"client"}
      data={batches}
      component={BatchListItem}
      onItemClick={(item) => goToBatch(item)}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListBatches;
