import React, { FC, useState } from "react";
import { ArrowLeft, ArrowRight } from "../icons";
import { mergeClassNames } from "../utils";

interface PaginatorType {
  pages?: number;
  selectPage: (page: number) => void;
  size?: "sm" | "md" | "lg";
}

const sizes = {
  sm: {
    icon: 18,
    textSize: "text-sm",
    padding: "lg:px-4 px-6 py-0",
  },
  md: {
    icon: 22,
    textSize: "text-md",
    padding: "lg:px-8 px-6 py-1",
  },
  lg: {
    icon: 25,
    textSize: "text-base",
    padding: "lg:px-8 px-6 py-1",
  },
};

export const Paginator: FC<PaginatorType> = ({
  pages = 1,
  selectPage,
  size = "lg",
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleChange = (newPage: number) => {
    setCurrentPage((prev: number) => newPage);
    selectPage(newPage);
  };

  return (
    <div className="flex flex-row gap-2 paginator">
      <button
        className={mergeClassNames(
          "bg-gray-100 border-gray-300 border-2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
        )}
        disabled={currentPage === 1}
        onClick={() => handleChange(currentPage - 1)}
      >
        <ArrowLeft size={sizes[size].icon} />
      </button>
      <select
        className={mergeClassNames(
          "bg-gray-100 border-gray-300 border-2 rounded-md font-bold bg-none text-center",
          sizes[size].padding,
          sizes[size].textSize
        )}
        onChange={(e) => handleChange(Number(e.target.value))}
        value={currentPage}
      >
        {Array.from({ length: pages }, (_, i) => i + 1).map((page, index) => (
          <option key={index} value={page}>
            {page}
          </option>
        ))}
      </select>
      <button
        className="bg-gray-100 border-gray-300 border-2 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={currentPage === pages}
        onClick={() => handleChange(currentPage + 1)}
      >
        <ArrowRight size={sizes[size].icon} />
      </button>
    </div>
  );
};
