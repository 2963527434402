import { MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ListApps from "../components/apps/list";
import { App, initialAppState } from "../components/apps/view";
import ManageAppModal from "../components/modal/manage-app";

import Content from "../layout/content";
import { Button } from "../lib";
import { listSDKsApi } from "../utils/api/apps-api";

interface Props {}

const Apps = ({}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [refreshList, setRefreshList] = useState(false);
  const [newApp, setNewApp] = useState<App>(initialAppState);
  const [sdks, setSdks] = useState<any>([]);
  const [isCreateNewAppModalOpen, setIsCreateNewAppModalOpen] = useState(false);
  const toggleModal = () => {
    setIsCreateNewAppModalOpen((prev) => !prev);
  };

  const handleCreateNewAppButtonClick = (
    _?: MouseEventHandler<HTMLButtonElement>
  ) => {
    setIsCreateNewAppModalOpen(true);
  };

  const fetchSdks = async () => {
    try {
      const response = await listSDKsApi();
      setSdks(response);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    fetchSdks();
  }, []);

  return (
    <Content
      isLoading={isLoading}
      title={t("apps")}
      actionBtns={
        <div className=" rounded-xl">
          <Button
            className={
              "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm h-full rounded-full"
            }
            onClick={() => handleCreateNewAppButtonClick()}
            colorScheme={"skyblue"}
            isRounded={true}
            label={t("addNewApp") as string}
          />
        </div>
      }
    >
      <ListApps
        refresh={refreshList}
        setRefresh={setRefreshList}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <ManageAppModal
        toggleModal={toggleModal}
        isOpen={isCreateNewAppModalOpen}
        setRefresh={setRefreshList}
        app={newApp}
        setApp={setNewApp}
        sdks={sdks}
      />
    </Content>
  );
};

export default Apps;
