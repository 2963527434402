import Api from ".";

export interface WebhookInterface {
  id?: string;
  url: string;
  method: string;
  event_id: string 
}
export interface WebhookListInterface {
  id?: string;
  url: string;
  method: string;
  event: {id: string, name: string, preview: {}}
}

export const listEventsApi = async () => {
  try {
    const response = await Api({
      method: "get",
      url: "get-events",
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listWebhooksApi = async ({ limit = 30, page = 1 }) => {
  try {
    const response = await Api({
      method: "get",
      url: "all-webhooks",
      params: {
        limit,
        page,
      },
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const createWebhookApi = async ({
  event_id,
  method,
  url,
}: WebhookInterface) => {
  try {
    const response = await Api({
      method: "post",
      url: "create-webhook",
      data: {
        event_id,
        method,
        url,
      },
    });
    return response.status;
  } catch (e: any) {
    throw e;
  }
};

export const updateWebhookApi = async ({
  id,
  event_id,
  method,
  url,
}: WebhookInterface) => {
  try {
    const response = await Api({
      method: "patch",
      url: `/update-webhook`,
      data: {
        id,
        event_id,
        method,
        url,
      },
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const removeWebhooksApi = async (id: string) => {
  try {
    const response = await Api({
      method: "delete",
      url: "remove-webhook",
      data: { id },
    });
    return response;
  } catch (e: any) {
    throw e;
  }
};

export const testWebhookApi = async ({ id }: { id: string }) => {
  try {
    const response = await Api({
      method: "post",
      url: "test-webhook",
      data: {
        id,
      },
    });
    return response.status;
  } catch (e: any) {
    throw e;
  }
};
export const listWebhookLogApi = async ({page = 1, limit = 15}) => {
  try {
    const response = await Api({
      method: "get",
      url: "all-webhooks-logs",
      params: {
        page,
        limit
      }
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const getWebhookLogApi = async (id: string) => {
  try {
    const response = await Api({
      method: "get",
      url: "get-webhook-log/" + id,
    });
    return response.data;
  } catch (e: any) {
    throw e;
  }
};
