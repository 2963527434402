import { FC, useEffect, useState } from "react";
import { Input, MerchantIcon, Select } from "../../lib";
import { Modal } from "../../lib/modal";
import { Button } from "../../lib";
import {
  newMerchantApi,
  updateMerchantApi,
} from "../../utils/api/merchants-apis";
import LoadingSpinner from "../../layout/loading-spinner";
import { useTranslation } from "react-i18next";
import { Feedback } from "../../lib/forms/feedback";
import i18next from "i18next";

const merchant_types = [
  { key: "66", value: "66" },
  { key: "77", value: "77" },
];
const countries = [{ key: "Kenya", value: "KE" }];
export type ManageMerchant = {
  id?: string;
  name: string;
  card_acceptor_id_code: string;
  card_acceptor_name_and_location: string;
  merchant_category_code: string;
  receipt_address: string;
};

export const initialNewMerchantState: ManageMerchant = {
  name: "",
  card_acceptor_id_code: "",
  card_acceptor_name_and_location: "",
  merchant_category_code: "",
  receipt_address: "",
};

type Props = {
  isOpen: boolean;
  newMerchant: ManageMerchant;
  refreshMerchant: React.Dispatch<React.SetStateAction<boolean>>;
  setNewMerchant: React.Dispatch<React.SetStateAction<ManageMerchant>>;
  toggleModal: () => void;
};

export const ManageMerchantModal: FC<Props> = ({
  isOpen,
  toggleModal,
  newMerchant,
  setNewMerchant,
  refreshMerchant,
}) => {
  const [createMerchantError, setCreateMerchantError] = useState<{
    [key: string]: string[];
  }>({});

  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [nameAndLocation, setNameAndLocation] = useState("");
  useEffect(() => {
    console.log(nameAndLocation);

    setNameAndLocation(location + city + state + country);
  }, [location, city, state, country]);
  const [merchantFeedback, setMerchantFeedback] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const save = async (e: any) => {
    try {
      setMerchantFeedback({});
      setIsLoading(true);
      e.preventDefault();
      let response;
      if (newMerchant.id) {
        response = await updateMerchantApi({
          id: newMerchant.id,
          name: newMerchant.name,
          card_acceptor_id_code: newMerchant.card_acceptor_id_code,
          card_acceptor_name_and_location: nameAndLocation,
          merchant_category_code: newMerchant.merchant_category_code,
          receipt_address: newMerchant.receipt_address,
        });
      } else
        response = await newMerchantApi({
          name: newMerchant.name,
          card_acceptor_id_code: newMerchant.card_acceptor_id_code,
          card_acceptor_name_and_location: nameAndLocation,
          merchant_category_code: newMerchant.merchant_category_code,
          receipt_address: newMerchant.receipt_address,
        });
      setIsLoading(false);
      setMerchantFeedback(response.message);

      setTimeout(() => {
        refreshMerchant((prevState: boolean) => !prevState);
        close();
      }, 3000);
    } catch (e: any) {
      setMerchantFeedback({});
      setIsLoading(false);
      setCreateMerchantError({ ...e?.message });
    }
  };
  const close = () => {
    setNewMerchant(initialNewMerchantState);
    setLocation("");
    setCity("");
    setState("");
    setCountry("");
    setMerchantFeedback({});
    setCreateMerchantError({});
    toggleModal();
    refreshMerchant((prevState: boolean) => !prevState);
    setIsLoading(false);
  };
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      close={() => close()}
      icon={MerchantIcon}
      title={newMerchant.id ? t("editMerchant") : t("createMerchant")}
      subTitle={t("merchant")}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <form>
        <Input
          label={t("name")}
          name="merchant-name"
          errors={createMerchantError?.name}
          onChange={(e) =>
            setNewMerchant((prevState: ManageMerchant) => ({
              ...prevState,
              name: e,
            }))
          }
          value={newMerchant?.name || ""}
        />
        <Input
          label={t("merchant_category_code")}
          name="merchant_category_code"
          onChange={(e) =>
            setNewMerchant((prevState: ManageMerchant) => ({
              ...prevState,
              merchant_category_code: e,
            }))
          }
          errors={createMerchantError?.merchant_category_code}
          value={newMerchant?.merchant_category_code || ""}
          length={4}
          pattern="[0-9]{4}"
          inputClasses=" invalid:focus:border-orange-400 invalid:focus:ring-orange-400 invalid:border-orange-400"
        />
        <Input
          label={t("Card Acceptor Id Code")}
          name="card_acceptor_id_code"
          errors={createMerchantError?.card_acceptor_id_code}
          onChange={(e) =>
            setNewMerchant((prevState: ManageMerchant) => ({
              ...prevState,
              card_acceptor_id_code: e,
            }))
          }
          value={newMerchant?.card_acceptor_id_code || ""}
        />
        <Input
          label={t("receiptAddress")}
          name="receipt_address"
          errors={createMerchantError?.receipt_address}
          onChange={(e) =>
            setNewMerchant((prevState: ManageMerchant) => ({
              ...prevState,
              receipt_address: e,
            }))
          }
          value={newMerchant?.receipt_address || ""}
        />
        <Input
          label={t("location")}
          name="location"
          onChange={(e) => setLocation(e)}
          value={location}
          length={23}
          inputClasses=" invalid:focus:border-orange-400 invalid:focus:ring-orange-400 invalid:border-orange-400"
        />
        <Input
          label={t("city")}
          name="city"
          onChange={(e) => setCity(e)}
          value={city}
          length={13}
          inputClasses=" invalid:focus:border-orange-400 invalid:focus:ring-orange-400 invalid:border-orange-400"
        />
        <Input
          label={t("state")}
          name="state"
          onChange={(e) => setState(e)}
          value={state}
          length={2}
          inputClasses=" invalid:focus:border-orange-400 invalid:focus:ring-orange-400 invalid:border-orange-400"
        />

        <Select
          data={countries as { [key: string]: any }[]}
          valueKey="value"
          displayKey="key"
          onChange={(country) => {
            setCountry(country);
          }}
          label={t("country")}
          placeholder={t("select") + " " + t("country")}
          name={"country"}
          value={country}
        />
        <Feedback
          colorScheme="green"
          message={
            i18next.language == "ar"
              ? merchantFeedback.arabic
              : merchantFeedback.english
          }
        />
        <div className="mt-6">
          <Button
            className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"}
            onClick={save}
            label={t("save") as string}
            colorScheme="black"
            isRounded={false}
            isDisabled={nameAndLocation.length != 40 ? true : false}
          />
        </div>
      </form>
    </Modal>
  );
};
