import React, { FC, HtmlHTMLAttributes } from "react";
import { Switch as TailwindSwitch } from "@headlessui/react";
import { mergeClassNames } from "../utils";

interface SwitchType extends React.HTMLAttributes<HTMLBodyElement> {
  isEnabled: boolean;
  setIsEnabled: (enabled: boolean) => void;
}

export const Switch: FC<SwitchType> = ({
  className,
  isEnabled,
  setIsEnabled,
}) => {
  return (
    <TailwindSwitch
      checked={isEnabled}
      onChange={setIsEnabled}
      className={mergeClassNames(
        isEnabled ? "bg-blue-600" : "bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500",
        className as string
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={mergeClassNames(
          isEnabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      />
    </TailwindSwitch>
  );
};
