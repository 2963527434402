import React, { FC } from "react";
import { IconType } from "./types";

export const ArrowUp: FC<IconType> = ({
  containerClassNames = "",
  size = 24,
  color = "text-black",
}) => {
  return (
    <div className={"p-1 "+ containerClassNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        width={size}
        height={size}
        className={"text-black " + color}
      >
         <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />

      </svg>
    </div>
  );
};

