import { currencyCodes } from './currency-codes';

export function mergeClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function capitalize(str: string): string {
  if (!str) return '';
  return str
    .split(' ')
    .map(
      (word: string) =>
        (word[0] || '').toUpperCase() + (word || '').substring(1)
    )
    .join(' ');
}

export const formatPrice = (
  amount: number | string,
  currency: string = '682',
  currencyExponent: number = 2
) => {
  return (
    currencyCodes[currency] +
    ' ' +
    (Number(amount) / Math.pow(10, currencyExponent)).toFixed(currencyExponent)
  );
};

export function isObjectEmpty(object: { [key: string]: any }) {
  return Object.keys(object).every((i) => object[i] === undefined)
    ? true
    : false;
}
