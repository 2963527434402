import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../layout/loading-spinner";
import { TerminalIcon, Modal, Button, Input, Select } from "../../lib";
import { createTRSMApi } from "../../utils/api/trsm-apis";

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  refreshList: () => void;
};

const ManageTRSM: FC<Props> = ({ isOpen, toggleModal, refreshList }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [TRSM, setTRSM] = useState("");
  const [TRSMResponse, setTRSMResponse] = useState<{
    [key: string]: any;
  }>({});
  const [TRSMErrors, setTRSMErrors] = useState<{
    [key: string]: any;
  }>({});
  const { t, i18n } = useTranslation();

  const submit = async () => {
    try {
      setIsLoading(true);
      setTRSMErrors({});
      const response = await createTRSMApi({
        trsm: TRSM,
      });
      setIsLoading(false);
      setTRSMResponse(response.trsm_code);

      refreshList();
      close();
    } catch (e: any) {
      console.log({ e });
      setTRSMErrors(e);
      setIsLoading(false);
    }
  };

  const updateTRSM = (value: string) => {
    if (/^[0-9A-Fa-f]{0,6}$/.test(value)) setTRSM(value);
  };

  const close = () => {
    setIsLoading(false);
    setTRSM("");
    setTRSMResponse({});
    setTRSMErrors({});
    toggleModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      icon={TerminalIcon}
      subTitle="TRSM"
      title={t("addNewTRSM")}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <div className="flex flex-col">
        <span className="font-bold text-black text-sm lg:text-base">TRSM</span>
        <Input
          inputContainerClasses="flex-1"
          label=""
          value={TRSM || ""}
          feedback={{
            message: TRSMErrors.trsm_code
              ? TRSMErrors.trsm_code
              : i18n.language == "ar"
              ? TRSMErrors?.error?.arabic
              : TRSMErrors?.error?.english,
            colorScheme: TRSMErrors?.error
              ? "red"
              : TRSMErrors.trsm_code
              ? "red"
              : "green",
          }}
          errors={TRSMErrors?.trsm_id || undefined}
          onChange={(value: string) => updateTRSM(value)}
          name={"terminal-" + 1}
        />
      </div>
      <div className="my-4">
        <Button
          isDisabled={!/^[0-9A-Fa-f]{6}$/.test(TRSM)}
          label={t("save") as string}
          onClick={submit}
          colorScheme="black"
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"}
          isRounded={false}
        />
      </div>
    </Modal>
  );
};

export default ManageTRSM;
