import React, { FC } from "react";
import { IconType } from "./types";

export const ArrowUpDown: FC<IconType> = ({
  containerClassNames = "",
  size = 24,
  color = "text-black",
}) => {
  return (
    <div className={"flex items-center " + containerClassNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        width={size}
        height={size}
        className={"text-black ltr:-mr-3 rtl:-ml-3"}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        width={size}
        height={size}
        className={"text-black " + color}
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3"
        />
      </svg>
    </div>
  );
};
