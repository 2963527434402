import { useEffect, useState } from "react";
import ListBox from "../shared/list-box";
import { dateRange } from "./date-picker";
import { Button } from "../../lib";
import DateRangePicker from "./date-picker";
import { useTranslation } from "react-i18next";

type props = {
  type: keyof typeof queryKeys;
  refreshList: boolean;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
  setDateRange: React.Dispatch<React.SetStateAction<dateRange>>;
  dateRange: dateRange;
  setQueryParams: React.Dispatch<
    React.SetStateAction<{
      [key: string]: any;
    }>
  >;
  queryParams: {
    [key: string]: any;
  };
};

const QueryBuilder = ({
  type,
  setRefreshList,
  setDateRange,
  dateRange,
  queryParams,
  setQueryParams,
}: props) => {
  const [queryValue, setQueryValue] = useState("");
  const [displayForInnerSelect, setDisplayForInnerSelect] = useState("");
  const [option, setOption] = useState<any>({});
  const [valueDisplay, setValueDisplay] = useState("");
  const [queryKeyIsSelected, setQueryKeyIsSelected] = useState(false);
  const [queryInputContainsValue, setQueryInputContainsValue] = useState(false);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    if (start === null && end === null) setDateRange({});
    else {
      setDateRange({
        from: start,
        to: end,
        fromTimestamp: (start as Date)?.getTime(),
        toTimestamp: (end as Date)?.getTime(),
      });
    }
    setRefreshList((prev) => !prev);
  };

  const deleteQueryParam = (key: string) => {
    setQueryParams((prev) => ({ ...prev, [key]: undefined }));
    setRefreshList((prev) => !prev);
  };
  const addQueryParam = (key: string, value: any, operator?: string) => {
    setQueryParams((prev) => ({
      ...prev,
      [key]: {
        value,
        operator,
        valueDisplay,
      },
    }));
    setRefreshList((prev) => !prev);
  };
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center query-builder">
      <div className="flex justify-between items-center bg-gray-100 p-4 flex-1 flex-wrap sm:flex-grow">
        <form>
          <div className=" flex gap-3 items-center flex-wrap sm:flex-grow">
            <div className="flex flex-initial">
              <ListBox
                onChange={() => {
                  setQueryValue("");
                  setQueryKeyIsSelected(true);
                }}
                options={queryKeys[type].options}
                selected={option}
                setSelected={setOption}
              />
              {option?.select?.length ? (
                <select
                  value={displayForInnerSelect}
                  onChange={(e) => {
                    setQueryValue(e.target.value);
                    setDisplayForInnerSelect(e.target.value);
                    setValueDisplay(
                      option.select?.find(
                        (selectOption: any) =>
                          selectOption.value == e.target.value
                      )?.selectDisplay
                    );
                    if (e.target.value == "") setQueryInputContainsValue(false);
                    else {
                      setQueryInputContainsValue(true);
                    }
                  }}
                  className="w-full cursor-default h-full rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value={""}></option>
                  {option?.select.map((option: any, index: number) => (
                    <option
                      onClick={() => {
                        setValueDisplay(option.selectDisplay);
                      }}
                      key={index}
                      value={option.value}
                    >
                      {option.key}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className="w-full cursor-default h-full rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 sm:text-sm"
                  onChange={(e) => {
                    setQueryValue(e.target.value);
                    e.target.value == ""
                      ? setQueryInputContainsValue(false)
                      : setQueryInputContainsValue(true);
                  }}
                  value={queryValue}
                />
              )}
            </div>
            <div>
              <Button
                isDisabled={!(queryInputContainsValue && queryKeyIsSelected)}
                colorScheme="blue"
                isRounded={false}
                className="px-4 py-2 text-xs"
                onClick={() => {
                  if (queryValue) {
                    addQueryParam(
                      option?.key as string,
                      queryValue,
                      option?.operator
                    );
                    setQueryValue("");
                    setDisplayForInnerSelect("");
                    setValueDisplay("");
                    setQueryInputContainsValue(false);
                  }
                }}
                label={t("search") as string}
              />
            </div>
          </div>
        </form>
        <div>
          <DateRangePicker onChange={onChange} dateRange={dateRange} />
        </div>
      </div>
      <div className="flex-1 flex p-4 gap-2">
        {Object.keys(queryParams).map((key: string, index) =>
          queryKeys[type].options.find((i: any) => i.key == key) &&
          queryParams[key]?.value ? (
            <div
              key={index}
              className="relative px-2 py-1 rounded-3xl border-2 shadow-sm border-gray flex items-center"
            >
              <div>
                {
                  queryKeys[type].options.find((i: any) => i.key == key)
                    ?.display
                }
                :{" "}
                {queryParams[key]?.valueDisplay
                  ? queryParams[key].valueDisplay
                  : queryParams[key]?.value}
              </div>
              <div
                onClick={() => deleteQueryParam(key)}
                className=" rounded-full h-4 w-4 flex items-center justify-center top-0 right-0 cursor-pointer ml-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default QueryBuilder;

export const queryKeys = {
  transactions: {
    options: [
      {
        key: "transaction_type",
        display: "Type",
        operator: "Equal",
        select: [
          { key: "Refund", value: "20", selectDisplay: "Refund" },
          { key: "Purchase", value: "00", selectDisplay: "Purchase" },
        ],
      },
      {
        key: "id",
        display: "RRN",
        operator: "Equal",
      },
      {
        key: "card_scheme_id",
        display: "Scheme ID",
        operator: "Equal",
        select: [
          { key: "Mada", value: "P1", selectDisplay: "Mada" },
          { key: "Mastercard", value: "MC", selectDisplay: "Mastercard" },
          { key: "Visa Credit", value: "VC", selectDisplay: "Visa Credit" },
          { key: "Discover", value: "DC", selectDisplay: "Discover" },
          { key: "JCB", value: "JC", selectDisplay: "JCB" },
          { key: "UnionPay", value: "UP", selectDisplay: "UnionPay" },
          { key: "GCCNET", value: "GN", selectDisplay: "GCCNET" },
          {
            key: "American Express",
            value: "AX",
            selectDisplay: "American Express",
          },
          { key: "Maestro", value: "DM", selectDisplay: "Maestro" },
        ],
      },
      {
        key: "receipts.action_code",
        display: "Action Code",
        operator: "Equal",
      },
      {
        key: "terminal.card_acceptor_terminal_id",
        display: "Terminal ID",
        operator: "Equal",
      },
      // {
      //   key: "receipts.merchant.id",
      //   display: "Merchant ID",
      //   operator: "Equal",
      // },
      // { key: "user_id", display: "User ID", operator: "Equal", select: [] },
      {
        key: "receipts.system_trace_audit_number",
        display: "STAN",
        operator: "Equal",
      },
    ],
  },
  reconciliations: {
    options: [
      {
        key: "system_trace_audit_number",
        display: "STAN",
        operator: "Equal",
      },
    ],
  },
};
