import { trackPromise } from "react-promise-tracker";
import Api, { List } from ".";
import { dateRange } from "../../components/query-builder/date-picker";
import { Transaction } from "../../types/transaction";

interface Transactions extends List {
  filter: { [key: string]: any };
  merchant_id?: string;
  terminal_id?: string;
  reconciliation_id?: string;
  queryParams: { [key: string]: any };
  dateRange?: dateRange;
}

interface TransactionsResponse {
  id: number;
  reference_retrieval_number: string;
  pan: string;
  qr_code: string;
  card_scheme_id: string;
  transaction_type: {
    name: {
      arabic: string;
      english: string;
    };
  };
  amount_authorized: {
    value: string;
  };
  currency: {
    arabic: string;
    english: string;
  };
  start_time: string;
  created_at: string;
}

export interface TransactionResponse {
  receipts: any[];
  id: number;
  is_reconciled: boolean;
  terminal_id: string;
  merchant_id: string;
  user_id: string;
  device_id: string;
  lat: string;
  lon: string;
  qr_code: string;
  amount_authorized: {
    value: string;
  };
  currency: {
    arabic: string;
    english: string;
  };
  scheme: {
    id: string;
  };
  transaction_type: {
    name: { arabic: string; english: string };
  };
  action_code: string;
  start_date: string;
  start_time: string;
  pan: string;
  verification_method: { arabic: string; english: string };
  card_expiration: string;
  reference_retrieval_number: string;
  system_trace_audit_number: string;
  status_message: { arabic: string; english: string };
  is_approved: boolean;
  client: any;
  terminal: any;
  device: any;
  user: any;
  merchant: any;
}

interface TransactionId {
  id: string;
}

interface TransactionDownload {
  from: number;
  to: number;
  queryParams: { [key: string]: any };
}

export const listTransactionsApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchant_id,
  terminal_id,
  reconciliation_id,
  queryParams,
  dateRange,
}: Transactions) => {
  let encodedQueryParams = btoa(JSON.stringify(queryParams));
  let encodeddateRange = btoa(JSON.stringify(dateRange));

  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "transactions",
        params: {
          limit,
          page,
          ...filter,
          merchant_id,
          terminal_id,
          reconciliation_id,
          queryParams: encodedQueryParams,
          dateRange: encodeddateRange,
        },
      })
    );
    return response.data as {
      pages: any;
      transactions: TransactionsResponse[];
    };
  } catch (e: any) {
    throw e;
  }
};

export const findTransactionApi = async ({ id }: TransactionId) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "transactions/" + id,
      })
    );
    return response.data as Transaction;
  } catch (e: any) {
    throw e;
  }
};

export const downloadTransactionApi = async ({
  from,
  to,
  queryParams = {},
}: TransactionDownload) => {
  let encodedQueryParams = btoa(
    unescape(encodeURIComponent(JSON.stringify(queryParams)))
  );
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "transactions/export",
        params: {
          from,
          to,
          queryParams: encodedQueryParams,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};
