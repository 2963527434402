import { useCallback, useState } from "react";
import { Button, Input, Errors } from "../lib/forms";
import { resetPasswordApi } from "../utils/api/auth-apis";
import { useNavigate } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../layout/loading-spinner";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as PaymentSchemeLogos } from "../assets/payment-schemes.svg";
import { usePromiseTracker } from "react-promise-tracker";

type Props = {};
const ResetPassword = ({}: Props) => {
  const [email, setEmail] = useState(process.env.REACT_APP_TESTING_EMAIL || "");
  const [resetPasswordErrors, setResetPasswordErrors] = useState<{
    [key: string]: any;
  }>({});
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const onVerify = useCallback((token: string) => {
    setRecaptchaToken(token);
  }, []);
  const { promiseInProgress: isLoading } = usePromiseTracker();

  const loginHandler = async () => {
    navigate("/");
  };

  const resetPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      setResetPasswordErrors({});
      await resetPasswordApi({ email, recaptchaToken });
      navigate("/message-sent");
    } catch (e: any) {
      console.log(e);
      setResetPasswordErrors(e);
    }
  };

  return (
    <div
      style={{ padding: "20px" }}
      className=" sm:justify-center flex flex-row h-full w-full relative py-10"
    >
      <LoadingSpinner isLoading={isLoading} isTransparent />
      <div className="flex flex-col h-full w-full md:w-3/4 max-w-2xl">
        <div className="flex items-center  flex-initial">
          <Logo />
        </div>
        <div className="flex flex-col w-full  justify-center flex-grow max-w-md m-auto">
          <form className="sm:p-5 flex flex-1 flex-col justify-center">
            <span className="text-5xl font-bold">
              {t("resetPasswordLabel")}
            </span>
            <div className="mb-4 mt-2">
              <Errors
                errors={
                  resetPasswordErrors.error
                    ? [
                        i18n.language == "ar"
                          ? resetPasswordErrors.error.arabic
                          : resetPasswordErrors.error.english,
                      ]
                    : []
                }
              />
            </div>
            <Input
              name="email"
              value={email}
              type="email"
              label={t("email")}
              onChange={setEmail}
              errors={resetPasswordErrors?.email as []}
            />
            <Button
              className={
                "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full py-2 px-4 mt-4"
              }
              label={t("sendEmail") as string}
              colorScheme="black"
              isRounded={false}
              onClick={resetPassword}
            />
            <Button
              className={
                "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full py-2 px-4"
              }
              label={t("login") as string}
              colorScheme="default"
              isRounded={false}
              onClick={loginHandler}
            />
          </form>
        </div>
        <div className="flex items-start justify-center">
          <PaymentSchemeLogos />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
