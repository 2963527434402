import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList, ReconciliationListItem } from "../../lib";
import { listReconciliationsApi } from "../../utils/api/reconcile-apis";
import { createFilterObject, ReconciliationsFilters } from "../filters";
import { dateRange } from "../query-builder/date-picker";

type Props = {
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  merchant_id?: string;
  terminal_id?: string;
  setIsLoading: any;
  onRowClick?: (payload: any) => void;
  activeTab?: string;
  limit?: number;
  setReconiliationsForDownload?: any;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

const ListReconciliations: FC<Props> = ({
  limit = 30,
  refresh,
  terminal_id,
  merchant_id,
  setIsLoading,
  activeTab = "all",
  setReconiliationsForDownload,
  queryParams = {},
  dateRange = {},
}: Props) => {
  const [reconciliations, setReconcile] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchReconciliations = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listReconciliationsApi({
        page,
        filter: createFilterObject(activeTab, ReconciliationsFilters),
        merchant_id,
        limit,
        terminal_id,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
     

      setPages(response.pages);
      setReconcile(response.receipts);
      setReconiliationsForDownload?.(response.receipts);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToReconcile = (reconcile: any) => {
    navigate("/reconciliations/" + reconcile?.id);
  };

  useEffect(() => {
    fetchReconciliations();
  }, [refresh, activeTab]);

  useEffect(() => {console.log({reconciliations},[reconciliations]);
  })
  return reconciliations?.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchReconciliations(page),
      }}
      onItemClick={(Reconciliation) => goToReconcile(Reconciliation)}
      role={"client"}
      data={reconciliations}
      component={ReconciliationListItem}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListReconciliations;
