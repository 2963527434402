import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Content from "../layout/content";
import { Button, Card, EmptyState } from "../lib";
import { getCredintialsApi, updateKey } from "../utils/api/apps-api";
import keypair from "keypair";
import * as fs from "fs";

type Props = {};

const Credentials: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState({} as any);
  const { t } = useTranslation();
  const [fingerPrint, setFingerPrint] = useState("");

  const getCredintials = async () => {
    try {
      await getCredintialsApi().then((res) => {
        setResponse(res);
        setIsLoading(false);
        setFingerPrint(res.fingerPrint);
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCredintials();
  }, []);

  return (
    <Content isLoading={isLoading}>
      <div className="flex flex-col">
        <div className="flex-1 flex flex-col justify-center">
          <div className="font-semibold flex-1 mt-3">{t("client")}</div>
          {Object.keys(response).length ? (
            <Client client_id={response?.client_id} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <div className="font-semibold flex-1 mt-3">{t("JWTKey")}</div>

          {Object.keys(response).length ? (
            <JWTKey fingerprint={fingerPrint} setFingerprint={setFingerPrint} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
        <div className="flex-1 flex flex-col justify-center">
          <div className="font-semibold flex-1 mt-3">API</div>
          {Object.keys(response).length ? (
            <Apikey apikey={response?.apikey} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
      </div>
    </Content>
  );
};

export default Credentials;

const JWTKey = ({
  fingerprint,
  setFingerprint,
}: {
  fingerprint: string;
  setFingerprint: any;
}) => {
  const { i18n, t } = useTranslation();

  const rsaKeys = async () => {
    return await keypair();
  };

  const handleRegenerateButton = async (e: SyntheticEvent) => {
    if (window.confirm("Such Action Will Cause Payment To Temporarily Stop"))
      try {
        const keys = await rsaKeys();
        await updateKey({ public_key: keys.public }).then((result) => {
          setFingerprint(result.fingerPrint);
          if (!result?.error) {
            let data = [keys.private];
            let key_file = new File(
              data,
              new Date().getTime() + "-private-key.pem"
            );
            const link = document.createElement("a");
            const url = URL.createObjectURL(key_file);
            link.href = url;
            link.download = key_file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }
        });
      } catch (error) {
        console.log({ error });
      }
  };

  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full ">
      <div className="flex flex-row items-center flex-wrap ">
        <div className={`p-2 ${i18n.language == "ar" ? "pl-4" : "pr-4"}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.758 11.828l7.849-7.849 1.414 1.414-1.414 1.415 2.474 2.474-1.414 1.415-2.475-2.475-1.414 1.414 2.121 2.121-1.414 1.415-2.121-2.122-2.192 2.192a5.002 5.002 0 0 1-7.708 6.294 5 5 0 0 1 6.294-7.708zm-.637 6.293A3 3 0 1 0 5.88 13.88a3 3 0 0 0 4.242 4.242z" />
          </svg>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-2 ">
            <span className="font-bold text-sm lg:text-base">
              {t("fingerprint")}
            </span>
          </div>
          <span
            className={
              i18n.language == "ar"
                ? "pl-3 break-all number-place-holder text-gray-600 text-xs text-right"
                : "pr-3 break-all text-gray-600 text-xs "
            }
          >
            {fingerprint}
          </span>
        </div>

        <div className="flex-initial">
          <Button
            className={
              "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm px-10 py-2"
            }
            label={fingerprint ? "Regenerate" : "Generate"}
            isRounded={true}
            onClick={handleRegenerateButton}
            colorScheme="black"
          />
        </div>
      </div>
    </Card>
  );
};

const Apikey = ({ apikey, onClick }: any) => {
  const { i18n } = useTranslation();
  const handleCopyButton = async () => {
    await navigator.clipboard.writeText(apikey);
    alert("api key copied to clipboard");
  };

  const CopyButton = ({
    handleCopyButton,
  }: {
    handleCopyButton: () => void;
  }) => {
    const { t } = useTranslation();
    return (
      <Button
        className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm px-10 py-2"}
        onClick={() => handleCopyButton()}
        label={t("Copy") as string}
        colorScheme={"black"}
      />
    );
  };

  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full">
      <div className="flex flex-row items-center">
        <div className={`p-2 ${i18n.language == "ar" ? "pl-4" : "pr-4"}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M5 11h14V5H5v6zm16-7v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1zm-2 9H5v6h14v-6zM7 15h3v2H7v-2zm0-8h3v2H7V7z" />
          </svg>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-2 ">
            <span className="font-bold text-sm lg:text-base ">API Key</span>
          </div>

          <span
            className={
              i18n.language == "ar"
                ? "w-36 sm:w-full truncate sm:max-w-full number-place-holder text-gray-600 text-sm text-right font-bold"
                : "w-36 sm:w-full truncate sm:max-w-full text-gray-600 text-sm font-bold"
            }
          >
            {apikey}
          </span>
        </div>

        <div className="flex-initial">
          <CopyButton handleCopyButton={handleCopyButton} />
        </div>
      </div>
    </Card>
  );
};

const Client = ({ client_id }: { client_id: string }) => {
  const { i18n } = useTranslation();
  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full">
      <div className="flex flex-row items-center">
        <div className={`p-2 ${i18n.language == "ar" ? "pl-4" : "pr-4"}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M21 5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H16v2h2V5h3zm-3 8h-2v2h-2v3h4v-5zm-2-2h-2v2h2v-2zm2-2h-2v2h2V9zm-2-2h-2v2h2V7z" />
          </svg>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-2 lg:my-1">
            <span className="font-bold text-sm lg:text-base">UUID</span>
          </div>
          <span
            className={
              i18n.language == "ar"
                ? "number-place-holder text-gray-600 text-xs text-right"
                : "text-gray-600 text-xs "
            }
          >
            {client_id}
          </span>
        </div>
      </div>
    </Card>
  );
};
