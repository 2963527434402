import DatePicker from "react-datepicker";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export type dateRange = {
  from?: Date;
  to?: Date;
  fromTimestamp?: number;
  toTimestamp?: number;
};

type props = {
  dateRange: dateRange;
  onChange: (
    date: [Date | null, Date | null],
    event: React.SyntheticEvent<any, Event> | undefined
  ) => void;
};

const DateRangePicker = ({ dateRange, onChange }: props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="relative w-60">
      <DatePicker
        selected={dateRange.from}
        onChange={onChange}
        selectsStart
        startDate={dateRange.from}
        isClearable
        clearButtonClassName={
          i18n.language == "ltr"
            ? "w-4 h-4 absolute top-1/2 right-0.5 transform  -translate-y-1/2 -translate-x-2 text-black"
            : "w-4 h-4 absolute top-1/2 left-0.5 transform  -translate-y-1/2 translate-x-2 text-black"
        }
        clearButtonTitle="X"
        endDate={dateRange.to}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        selectsRange
        popperClassName="react-datepicker-left"
        placeholderText={t("selectRange")}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          increaseYear,
          decreaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex flex-row gap-2">
            <div className="flex items-center justify-between  py-1">
              <button
                onClick={decreaseYear}
                type="button"
                className={`inline-flex p-1 text-sm font-medium text-gray-700 bg-white  rounded  hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-blue-600`}
              >
                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
              </button>
              <span className="text-lg text-gray-700">
                {format(date, "yyyy")}
              </span>
              <button
                onClick={increaseYear}
                type="button"
                className={`inline-flex p-1 text-sm font-medium text-gray-700 bg-white  rounded  hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-blue-600`}
              >
                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
            <div className="flex items-center justify-between w-full py-1">
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
                className={`${
                  prevMonthButtonDisabled && "cursor-not-allowed opacity-50"
                } inline-flex p-1 text-sm font-medium text-gray-700 bg-white rounded  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
              >
                <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
              </button>
              <span className="text-lg text-gray-700">
                {format(date, "MMMM")}
              </span>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                type="button"
                className={`${
                  nextMonthButtonDisabled && "cursor-not-allowed opacity-50"
                }inline-flex p-1 text-sm font-medium text-gray-700 bg-white rounded  hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-blue-500`}
              >
                <ChevronRightIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default DateRangePicker;
