import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EmptyState, GroupedList, TransactionListItem } from "../../lib";
import { listTransactionsApi } from "../../utils/api/transactions-apis";
import { createFilterObject, TransactionsFilters } from "../filters";
import { dateRange } from "../query-builder/date-picker";

type Props = {
  limit?: number;
  activeTab?: string;
  refresh: boolean;
  terminal_id?: string;
  reconciliation_id?: string;
  merchant_id?: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTransactionsForDownload?: any;
  queryParams?: {
    [key: string]: any;
  };
  dateRange?: dateRange;
};

const ListTransactions: FC<Props> = ({
  refresh,
  terminal_id,
  merchant_id,
  reconciliation_id,
  setIsLoading,
  activeTab,
  limit = 30,
  setTransactionsForDownload,
  queryParams = {},
  dateRange = {},
}: Props) => {
  const [transactions, setTransactions] = useState<any>([]);
  const [pages, setPages] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchTransactions = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listTransactionsApi({
        page,
        filter: createFilterObject(
          activeTab ? activeTab : "",
          TransactionsFilters
        ),
        merchant_id,
        limit,
        terminal_id,
        reconciliation_id,
        queryParams: queryParams as {
          [key: string]: any;
        },
        dateRange,
      });
      setPages(response.pages);
      console.log({ res: response.transactions });

      setTransactions(response.transactions);
      setTransactionsForDownload?.(response.transactions);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  const goToTransaction = (transaction: any) =>
    navigate("/transactions/" + transaction.id);

  useEffect(() => {
    fetchTransactions();
  }, [refresh, activeTab]);

  return transactions?.length ? (
    <GroupedList
      pages={{
        total: pages,
        selectPage: (page: number) => fetchTransactions(page),
      }}
      onItemClick={(item) => goToTransaction(item)}
      role={"client"}
      data={transactions}
      component={TransactionListItem}
      groupBy={{
        key: "created_at",
        isDate: true,
      }}
    />
  ) : (
    <EmptyState title={t("itemNotFound")} />
  );
};

export default ListTransactions;
