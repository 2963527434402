import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight, TerminalIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";
import { TerminalListItemType } from "../types/terminal";

export const TerminalListItem: FC<TerminalListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  const { t, i18n } = useTranslation();
  const isRegisteredBadge: BadgeType = item?.is_assigned_to_user
    ? { label: t("terminalAssinged"), color: "gray" }
    : { label: t("terminalNotAssinged"), color: "green" };
  const isConnectedBadge: BadgeType = item?.is_connected
    ? { label: t("deviceConnected"), color: "gray" }
    : { label: t("deviceNotConnected"), color: "green" };
  return (
    <div className="mt-3">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 w-full" onClick={() => onClick?.(item)}>
        <div className="flex flex-row items-center">
          <TerminalIcon
            containerClassNames={i18n.language == "ar" ? "pl-4" : "pr-4"}
          />
          <div
            className={
              i18n.language == "ar"
                ? "flex flex-col flex-1 lg:flex-initial ml-8"
                : "flex flex-col flex-1 lg:flex-initial mr-8"
            }
          >
            <span className="text-gray-600 text-xs">
              {" "}
              {t("terminalsIdentifier") as string}
            </span>
            <span className="flex-1 font-bold text-xs lg:text-base">
              {item?.name ? item?.name : "#" + item?.tid}
            </span>
          </div>
          <div className="hidden flex-1 lg:flex flex-row gap-4">
            <Badge isRounded={true} {...isRegisteredBadge} />
            <Badge isRounded={true} {...isConnectedBadge} />
          </div>
          <div className="flex-initial flex flex-row">
            <div className="text-xs lg:text-base flex-1 flex flex-col lg:text-left">
              <span className="text-gray-400">{t("user") as string}</span>
              <span className="flex-1 font-bold w-16 truncate lg:w-full">
                {item?.user?.name || "---"}
              </span>
            </div>
            {onClick ? (
              <div className="flex-initial">
                {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
              </div>
            ) : null}
          </div>
          {/* <div className="flex-initial"></div> */}
        </div>
      </Card>
    </div>
  );
};
