import { performance } from 'perf_hooks';
import { useTranslation } from 'react-i18next';
import { EmptyState } from '../../lib';
import { performanceElement, Transaction } from '../../types/transaction';

const PerformanceBar = ({ transaction }: { transaction: Transaction }) => {
  const { t } = useTranslation();

  const mada =
    (transaction?.performance.find((i) => i.type === 'received')?.time_stamp ||
      0) -
    (transaction?.performance?.find((i) => i.type === 'send')?.time_stamp || 0);
  const backend =
    (transaction?.performance?.find((i) => i.type === 'server_end')
      ?.time_stamp || 0) -
    (transaction?.performance?.find((i) => i.type === 'server_start')
      ?.time_stamp || 0) -
    mada;
  const internet =
    (transaction?.performance?.find((i) => i.type === 'mobile_receive_response')
      ?.time_stamp || 0) -
    (transaction?.performance?.find((i) => i.type === 'mobile_send_tlv')
      ?.time_stamp || 0) -
    backend -
    mada;
  const mobile =
    (transaction?.performance?.find((i) => i.type === 'mobile_display_response')
      ?.time_stamp || 0) -
    (transaction?.performance?.find((i) => i.type === 'mobile_read_tlv')
      ?.time_stamp || 0) -
    internet -
    backend -
    mada;
  const performance = mada + backend + internet + mobile;

  const performanceArray = [
    { label: 'Reader', value: mobile, unit: 'ms', color: '#233050' },
    { label: 'Server', value: backend, unit: 'ms', color: '#912F40' },
    { label: 'Host', value: mada, unit: 'ms', color: '#E9D985' },
    { label: 'Network', value: internet, unit: 'ms', color: '#8B94A3' },
  ];

  return performance ? (
    <div className="flex flex-col py-2 px-4 ">
      <span className="text-md lg:text-lg flex justify-between">
        <span> {t('performance')}</span>
        <span className="rounded bg-blue-900 px-2 text-sm text-white flex items-center">{`${performance}ms`}</span>
      </span>
      <span className={'py-2'}>
        <>
          <div
            className="ltr"
            style={{
              height: '22px',
              whiteSpace: 'nowrap',
            }}
          >
            {performanceArray.map((item) => {
              return (
                <span
                  className="inner-performance"
                  key={item.label}
                  style={{
                    backgroundColor: item.color,
                    width: `${((item.value / performance) * 100).toFixed(2)}%`,
                  }}
                  data-title={`${item.label}: ${item.value}${item.unit}`}
                ></span>
              );
            })}
          </div>
          <div className="grid 3xl:grid-cols-4  grid-cols-1 sm:grid-cols-2">
            {performanceArray.map((item) => {
              return (
                <div
                  key={item.label}
                  className=" pt-2 gap-2"
                  data-title={`${item.label}: ${item.value}${item.unit}`}
                >
                  <div className="flex items-center">
                    <div
                      className="w-3 h-3 float-left rounded-full "
                      style={{
                        backgroundColor: item.color,
                      }}
                    ></div>
                    <div className="px-2">
                      {item.label}: {item.value}
                      {item.unit},
                    </div>

                    <div className="px-2">
                      {((item.value / performance) * 100).toFixed(2)}%
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      </span>
    </div>
  ) : (
    <EmptyState title="Placeholder" />
  );
};

export default PerformanceBar;
