import { FC, PropsWithChildren, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {};

export const ScrollToTop: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { pathname } = useLocation();

  // function refreshPage() {
  //   window.location.reload();
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};
