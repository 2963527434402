import { t } from "i18next";
import React from "react";
import LoadingSpinner from "../../layout/loading-spinner";
import { Button, Modal } from "../../lib";
import DatePickerUI from "../shared/data_picker";

type Props = {
  isModalOpen: boolean;
  feedback: string;
  isLoading: boolean;
  toggleModal: () => void;
  setDateRange: React.Dispatch<
    React.SetStateAction<{
      startDate: number;
      endDate: number;
    }>
  >;
  dateRange?: { startDate: number; endDate: number };
  submit: () => void;
  title: string;
};
const ReceiptsDownloadModal = ({
  isModalOpen,
  feedback,
  isLoading,
  toggleModal,
  setDateRange,
  dateRange,
  submit,
  title,
}: Props) => {
  return (
    <Modal
      isOpen={isModalOpen}
      close={toggleModal}
      subTitle={"Select Date Range"}
      title={title}
      icon={"symbol"}
    >
      <div className="flex flex-col gap-3 items-center ">
        {feedback && (
          <span className="bg-red-100 text-red-600 text-xs font-semibold capitalize w-full text-center p-1 rounded">
            {feedback}
          </span>
        )}
        <DatePickerUI setDateRange={setDateRange}></DatePickerUI>
        <LoadingSpinner isLoading={isLoading} />
        <Button
          onClick={submit}
          isDisabled={!dateRange?.startDate || !dateRange.endDate}
          colorScheme={"black"}
          className={"px-2 py-1"}
          label={t("download" + title) as string}
          // classNames={classNames}
        />
      </div>
    </Modal>
  );
};

export default ReceiptsDownloadModal;
