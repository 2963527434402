import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import BackButton from "../../components/shared/back-button";
import { ReactComponent as Tick } from "../../assets/tick.svg";
import {
  Button,
  Errors,
  Input,
  MerchantIcon,
  Select,
  Switch,
  TerminalIcon,
  UserIcon,
} from "../../lib";
import { Feedback } from "../../lib/forms/feedback";
import { MerchantsListItem } from "../../lib/list-item/merchant-list";
import {
  AssignUserType,
  batchData,
  createMerchantObject,
  TrsmToBeRegistered,
} from "../../types/batch";
import {
  batchAssignUsersApi,
  getBatchApi,
  newMerchantApi,
  newMerchantsApi,
} from "../../utils/api/batch-apis";
import {
  linkMerchantApi,
  listMerchantsApi,
} from "../../utils/api/merchants-apis";
import { clientCreateAndRegisterTrsmAndTerminalApi } from "../../utils/api/terminals-apis";

const BatchUploadTable = () => {
  const { id } = useParams();
  const [batchData, setBatchData] = useState<batchData>({});
  const [refresh, setRefresh] = useState(false);
  const [terminalsToBeRegistered, setTerminalsToBeRegistered] = useState<
    TrsmToBeRegistered[]
  >([]);

  const [merchantsToBeCreated, setMerchantsToBeCreated] = useState<
    createMerchantObject[]
  >([]);
  const [usersToBeAssigned, setUsersToBeAssigned] = useState<AssignUserType[]>(
    []
  );
  const { t, i18n } = useTranslation();
  const [alreadyExistingMerchants, setAlreadyExistingMerchants] = useState<any>(
    []
  );
  const handleLinkToMerchantSelect = async ({
    merchant_id,
    merchant_name,
  }: {
    merchant_id: string;
    merchant_name: string;
  }) => {
    if (merchant_id.length > 1)
      await linkMerchantApi({
        merchant_id,
        merchant_name,
        batch_id: id as string,
      }).then((result) => {
        updateBatchData(result);
      });
  };

  const handleMerchantsCreationSwitch = (
    is_new: boolean,
    { merchant_name_english, merchant_name_arabic }: createMerchantObject
  ) => {
    if (is_new)
      setMerchantsToBeCreated((prev) => {
        return [...prev, { merchant_name_arabic, merchant_name_english }];
      });
    else
      setMerchantsToBeCreated((prev) => {
        let newValue = [...prev];
        let deletedElementIndex = prev.findIndex(
          (element) =>
            element.merchant_name_english == merchant_name_english &&
            element.merchant_name_arabic == merchant_name_arabic
        );
        if (deletedElementIndex >= 0) {
          newValue.splice(deletedElementIndex, 1);
        }
        return newValue;
      });
  };
  const handleAssignUsers = async () => {
    await batchAssignUsersApi({
      users: usersToBeAssigned,
      batch_id: id as string,
    }).then((result) => {
      updateBatchData(result);
      setUsersToBeAssigned([]);
    });
  };
  const handleCreateSelectedMerchants = async () => {
    await newMerchantsApi({
      merchants: merchantsToBeCreated,
      batch_id: id as string,
    }).then((result) => {
      updateBatchData(result);
      setMerchantsToBeCreated([]);
    });
  };

  const createMerchantHandler = async ({
    merchant_name_english,
    merchant_name_arabic,
  }: {
    merchant_name_english: string;
    merchant_name_arabic: string;
  }) => {
    await newMerchantApi({
      merchant_name_english: merchant_name_english,
      merchant_name_arabic: merchant_name_arabic,
      batch_id: id as string,
    }).then((result) => {
      updateBatchData(result);
      setRefresh((prev) => !prev);
    });
    setMerchantsToBeCreated((prev) => {
      let newValue = [...prev];
      let deletedElementIndex = prev.findIndex(
        (element) =>
          element.merchant_name_english == merchant_name_english &&
          element.merchant_name_arabic == merchant_name_arabic
      );
      if (deletedElementIndex >= 0) {
        newValue.splice(deletedElementIndex, 1);
      }
      return newValue;
    });
  };

  const updateBatchData = (result: any) => {
    setBatchData(groupByStep(result?.currentState, "merchant_name"));
  };
  const handleCreateButton = async () => {
    try {
      clientCreateAndRegisterTrsmAndTerminalApi({
        trsms: terminalsToBeRegistered,
        batch_id: id as string,
      }).then((result) => {
        updateBatchData(result?.response);
        setTerminalsToBeRegistered([]);
      });
    } catch (e) {
      console.log(e);
    }
  };
  const getMerchants = async () => {
    const merchants = await listMerchantsApi({});
    setAlreadyExistingMerchants(merchants.merchants);
  };
  const groupByStep = (array: any[], key: string) => {
    const TrsmGroupByMerchant = array.reduce((group: any, current) => {
      group[current?.[key]] = {
        ...group[current?.[key]],
        trsms: group[current?.[key]]?.trsms || [],
        status: group[current?.[key]]?.status || undefined,
        merchant_name_english: current?.merchant_name,
        merchant_name_arabic: current?.merchant_arabic,
        id: group[current?.[key]]?.id || current?.id,
      };
      group[current?.[key]]?.trsms?.push({
        trsm: current?.trsm,
        user_name: current.user_name || undefined,
        user_mobile: current?.user_mobile,
        message: current?.message,
        user_email: current?.user_email,
        user_id: current?.user_id,
        user: current.user || {},
        terminal_id: current.terminal_id || undefined,
      });

      return group;
    }, {});
    return TrsmGroupByMerchant;
  };
  const toggleAssignUser = (userToBeAssigned: AssignUserType) => {
    if (
      usersToBeAssigned.some((user) => {
        return (
          user.user_mobile == userToBeAssigned.user_mobile &&
          user.terminal_id == userToBeAssigned.terminal_id
        );
      })
    ) {
      setUsersToBeAssigned((prev) =>
        prev.filter((user) => user.user_mobile != userToBeAssigned.user_mobile)
      );
    } else {
      setUsersToBeAssigned((prev) => [...prev, userToBeAssigned]);
    }
  };

  const toggleMerchantSelectAll = async ({
    e,
    merchant,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    merchant: string;
  }) => {
    if (e.target.checked) {
      let updatedTerminals = [...terminalsToBeRegistered];
      batchData[merchant].trsms
        .filter(
          (i: any) => !terminalsToBeRegistered.some((j) => j.trsm == i.trsm)
        )
        .forEach((terminal: any) => {
          updatedTerminals.push({
            trsm: terminal.trsm,
            merchant_id: batchData[merchant].id,
          });
        });
      setTerminalsToBeRegistered(updatedTerminals);
    } else {
      let updatedTerminals = terminalsToBeRegistered.filter(
        (terminalToKeep) => terminalToKeep.merchant_id != batchData[merchant].id
      );
      setTerminalsToBeRegistered(updatedTerminals);
    }
  };

  const toggleTRSMCreate = async ({
    e,
    trsm,
    merchant_id,
  }: {
    e: React.ChangeEvent<HTMLInputElement>;
    trsm?: string;
    merchant_id: string;
  }) => {
    if (e.target.checked) {
      let updatedTerminals = [...terminalsToBeRegistered];
      updatedTerminals.push({
        trsm: trsm as string,
        merchant_id,
      });
      setTerminalsToBeRegistered(updatedTerminals);
    } else {
      let updatedTerminals = [...terminalsToBeRegistered];
      updatedTerminals = updatedTerminals.filter((i) => i.trsm !== trsm);
      setTerminalsToBeRegistered(updatedTerminals);
    }
  };

  const getBatch = async () => {
    await getBatchApi({ id: id as string }).then((result) => {
      updateBatchData(result);
    });
  };

  useEffect(() => {
    getBatch();
    getMerchants();
  }, []);
  useEffect(() => {
    getMerchants();
  }, [refresh]);

  return (
    <>
      <div className="flex gap-3 my-2 items-center mx-2 lg:mx-0">
        <BackButton />
        <div className="text-sm font-bold">{t("goBack") as string} </div>
      </div>
      <div className="flex flex-col bg-white  xl:px-0 my-4 rounded-lg shadow">
        <div className="w-full sm:max-w-8xl mx-auto bg-white rounded-lg ">
          <div className="overflow-x-auto rounded-lg">
            <table className="table-auto w-full">
              <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                <tr>
                  <th className="p-1 sm:p-2 w-1/4">
                    <div className="font-semibold text-left text-base">
                      Merchant
                    </div>
                  </th>
                  <th className="p-1 sm:p-2 w-1/4">
                    <div className="font-semibold text-left text-base">
                      Registered
                    </div>
                  </th>
                  <th className="p-1 sm:p-2 w-1/4">
                    <div className="font-semibold text-left text-base">
                      Total
                    </div>
                  </th>
                  <th className="p-1 sm:p-2 w-1/4">
                    <div className="font-semibold text-center text-base">
                      Link to merchant
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {Object.keys(batchData).map((merchant, index) => {
                  return (
                    <tr key={index}>
                      <td className="w-full min-w-max " colSpan={5}>
                        <details className="flex flex-wrap batch-details">
                          <summary className="px-2 py-1 flex items-center hover:bg-gray-100 hover:shadow-sm w-full flex-wrap batch-summary">
                            <div
                              className={`font-medium text-gray-800 w-1/4 truncate flex items-center`}
                            >
                              {batchData[merchant].trsms?.every(
                                (terminal: any) => terminal.terminal_id
                              ) && batchData[merchant].id ? (
                                <Tick height={12} width={12} />
                              ) : (
                                <input
                                  type="checkbox"
                                  className={`w-3 h-3 rounded focus:ring-transparent ${
                                    !batchData[merchant].id ? " invisible " : ""
                                  }`}
                                  checked={batchData[merchant].trsms.every(
                                    (i: any) =>
                                      terminalsToBeRegistered.some(
                                        (j) => i.trsm == j.trsm
                                      )
                                  )}
                                  onChange={(e) =>
                                    toggleMerchantSelectAll({
                                      merchant,
                                      e,
                                    })
                                  }
                                />
                              )}

                              {batchData[merchant].id ? (
                                <div className="px-2">
                                  <MerchantsListItem
                                    version="batch-upload"
                                    role="client"
                                    item={alreadyExistingMerchants.find(
                                      (i: any) => i.id == batchData[merchant].id
                                    )}
                                  />
                                </div>
                              ) : (
                                <span className="px-2 gap-2 flex ">
                                  <div>
                                    {batchData[merchant].merchant_name_english}
                                  </div>
                                  <span>-</span>
                                  <div>
                                    {batchData[merchant].merchant_name_arabic}
                                  </div>
                                </span>
                              )}
                            </div>

                            <div className="text-left text-green-500 w-1/4 px-2">
                              {
                                batchData[merchant].trsms.filter(
                                  (trsm: any) => trsm.terminal_id
                                ).length
                              }
                            </div>

                            <div className="text-left font-medium w-1/4 px-2">
                              {batchData[merchant].trsms.length}
                            </div>
                            <div className="flex justify-center w-1/4">
                              {batchData[merchant].id ? (
                                ""
                              ) : (
                                <div className=" w-full flex flex-col ">
                                  {
                                    <div className="flex  justify-between items-center">
                                      <div className="flex gap-2 rounded-lg  px-2 items-center">
                                        create new ?
                                        <Switch
                                          className="custom-batch-switch"
                                          isEnabled={merchantsToBeCreated.some(
                                            (
                                              createMerchantObject: createMerchantObject
                                            ) =>
                                              createMerchantObject.merchant_name_english ==
                                                batchData[merchant]
                                                  .merchant_name_english &&
                                              createMerchantObject.merchant_name_arabic ==
                                                batchData[merchant]
                                                  .merchant_name_arabic
                                          )}
                                          setIsEnabled={(enabled) =>
                                            handleMerchantsCreationSwitch(
                                              enabled,
                                              {
                                                merchant_name_english:
                                                  batchData[merchant]
                                                    .merchant_name_english,
                                                merchant_name_arabic:
                                                  batchData[merchant]
                                                    .merchant_name_arabic,
                                              }
                                            )
                                          }
                                        />
                                      </div>
                                      {merchantsToBeCreated.some(
                                        (
                                          createMerchantObject: createMerchantObject
                                        ) =>
                                          createMerchantObject.merchant_name_english ==
                                            batchData[merchant]
                                              .merchant_name_english &&
                                          createMerchantObject.merchant_name_arabic ==
                                            batchData[merchant]
                                              .merchant_name_arabic
                                      ) ? (
                                        <Button
                                          onClick={() =>
                                            createMerchantHandler({
                                              merchant_name_arabic:
                                                batchData[merchant]
                                                  .merchant_name_arabic,
                                              merchant_name_english:
                                                batchData[merchant]
                                                  .merchant_name_english,
                                            })
                                          }
                                          label={t("create") as string}
                                          className={
                                            "p-1 max-h-10 text-xs custom-batch-button"
                                          }
                                          isRounded={false}
                                          colorScheme={"black"}
                                        />
                                      ) : (
                                        <Select
                                          onChange={(e) =>
                                            handleLinkToMerchantSelect({
                                              merchant_id: e,
                                              merchant_name: merchant,
                                            })
                                          }
                                          name="merchant"
                                          selectClasses=" custom-batch-selector "
                                          onClick={(e) => {
                                            e?.stopPropagation();
                                          }}
                                          data={[...alreadyExistingMerchants]}
                                          value={
                                            batchData[merchant].id as string
                                          }
                                          valueKey={"id"}
                                          displayKey={"name"}
                                          placeholder={
                                            t(
                                              "select existing merchant"
                                            ) as string
                                          }
                                        />
                                      )}
                                    </div>
                                  }
                                </div>
                              )}
                            </div>
                          </summary>
                          {/* Inner Table Starts Here*/}
                          <div className="w-full ">
                            <table className="table-auto w-full">
                              <thead className="text-xs font-semibold uppercase text-blue-300 bg-blue-50">
                                <tr>
                                  <th className="p-2 w-1/6 min-w-max">
                                    <div className="font-semibold text-left">
                                      TRSM
                                    </div>
                                  </th>
                                  <th className="w-1/6 text-left">Name</th>
                                  <th className="p-2 w-1/6">
                                    <div className="font-semibold text-left">
                                      Mobile
                                    </div>
                                  </th>
                                  <th className=" w-1/6 truncate text-left">
                                    Email
                                  </th>
                                  <th className="w-1/6 truncate text-left">
                                    Action
                                  </th>
                                  <th className="text-left w-1/5">result</th>
                                </tr>
                              </thead>
                              <tbody>
                                {batchData[merchant].trsms.map(
                                  (trsmObject: any, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        className={`border-b border-blue-100 ${
                                          trsmObject.status === "created" ?? ""
                                        }`}
                                      >
                                        <td className="p-2 w-full " colSpan={6}>
                                          <div
                                            className={`flex w-full ${
                                              batchData[merchant]?.id ==
                                              undefined
                                                ? "text-gray-300"
                                                : ""
                                            }`}
                                          >
                                            <div className="w-1/6 min-w-max ">
                                              <input
                                                type="checkbox"
                                                disabled={
                                                  batchData[merchant]?.id ==
                                                  undefined
                                                }
                                                className={`w-3 h-3 rounded focus:ring-transparent ${
                                                  batchData[merchant]?.id ==
                                                  undefined
                                                    ? "border-gray-300"
                                                    : trsmObject.terminal_id
                                                    ? "hidden"
                                                    : ""
                                                }`}
                                                checked={terminalsToBeRegistered.some(
                                                  (i) =>
                                                    i.trsm == trsmObject.trsm
                                                )}
                                                onChange={(e) =>
                                                  toggleTRSMCreate({
                                                    e,
                                                    trsm: trsmObject.trsm,
                                                    merchant_id:
                                                      batchData[merchant].id,
                                                  })
                                                }
                                              />
                                              <span className="px-2">
                                                {trsmObject?.trsm}
                                              </span>
                                            </div>

                                            <div className="hidden sm:w-1/6 sm:flex truncate ">
                                              {trsmObject?.user_name ===
                                              trsmObject?.user?.name ? (
                                                trsmObject?.user_name
                                              ) : trsmObject.user_id ? (
                                                <span>
                                                  {trsmObject.user_name}
                                                  <span className="font-bold">
                                                    {" or "}
                                                  </span>
                                                  {trsmObject?.user?.name}
                                                </span>
                                              ) : (
                                                trsmObject?.user_name
                                              )}
                                            </div>
                                            <div className="w-1/6 truncate">
                                              {trsmObject?.user_mobile}
                                            </div>

                                            <div className="sm:w-1/6 justify-start relative items-center flex ">
                                              {trsmObject?.user_email}
                                            </div>
                                            <div
                                              className={`sm:w-1/6 items-center relative flex justify-start gap-2 ${
                                                !trsmObject.terminal_id
                                                  ? "text-gray-300"
                                                  : trsmObject.user_id
                                                  ? "invisible"
                                                  : ""
                                              }`}
                                            >
                                              Assign User
                                              <input
                                                type="checkbox"
                                                disabled={
                                                  batchData[merchant]?.id ==
                                                    undefined ||
                                                  trsmObject.user_id ||
                                                  !trsmObject.terminal_id
                                                }
                                                className={`w-3 h-3 rounded focus:ring-transparent ${
                                                  trsmObject.user_id
                                                    ? "invisible"
                                                    : ""
                                                } ${
                                                  batchData[merchant]?.id ==
                                                    undefined ||
                                                  !trsmObject.terminal_id
                                                    ? "border-gray-300"
                                                    : ""
                                                }`}
                                                checked={
                                                  trsmObject.user_id ||
                                                  usersToBeAssigned.some(
                                                    (user) =>
                                                      user.user_mobile ==
                                                        trsmObject.user_mobile &&
                                                      user.terminal_id ==
                                                        trsmObject.terminal_id
                                                  )
                                                }
                                                onChange={(e) =>
                                                  toggleAssignUser({
                                                    user_email:
                                                      trsmObject.user_email,
                                                    user_mobile:
                                                      trsmObject.user_mobile,
                                                    user_name:
                                                      trsmObject.user_name,
                                                    terminal_id:
                                                      trsmObject.terminal_id,
                                                  })
                                                }
                                              />
                                            </div>
                                            <div className="w-3/5 sm:w-1/6 flex justify-center truncate">
                                              {trsmObject?.message?.is_error ===
                                              false ? (
                                                <Feedback
                                                  colorScheme="green"
                                                  message={
                                                    trsmObject?.message
                                                      ?.message?.[
                                                      i18n.dir() === "ltr"
                                                        ? "english"
                                                        : "arabic"
                                                    ]
                                                  }
                                                />
                                              ) : trsmObject?.message
                                                  ?.is_error === true ? (
                                                <Errors
                                                  errors={[
                                                    trsmObject?.message
                                                      ?.message?.[
                                                      i18n.dir() === "ltr"
                                                        ? "english"
                                                        : "arabic"
                                                    ],
                                                  ]}
                                                />
                                              ) : null}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                          {/** inner table ends here */}
                        </details>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* </Content> */}
      <div className="flex flex-col bg-white p-2 my-4 rounded-lg shadow">
        <div className="flex justify-end gap-2">
          <Button
            colorScheme={"black"}
            className="py-1  ltr:pr-5 rtl:pl-5 px-2 text-xs sm:text-base "
            isRounded={false}
            label={t("registerSelectedTerminals") as string}
            onClick={() => handleCreateButton()}
            isDisabled={terminalsToBeRegistered.length == 0}
            icon={<TerminalIcon color="white" />}
          />
          <Button
            colorScheme={"black"}
            className="py-1 ltr:pr-5 rtl:pl-5 px-2 text-xs sm:text-base"
            isRounded={false}
            onClick={() => handleCreateSelectedMerchants()}
            label={t("registerSelectedMerchants") as string}
            isDisabled={merchantsToBeCreated.length == 0}
            icon={<MerchantIcon color="white" />}
          />
          <Button
            colorScheme={"black"}
            className="py-1 px-2 text-xs ltr:pr-5 rtl:pl-5 sm:text-base"
            isRounded={false}
            onClick={() => handleAssignUsers()}
            label={t("assignSelectedUsers") as string}
            isDisabled={usersToBeAssigned.length == 0}
            icon={<UserIcon color="white" />}
          />
        </div>
      </div>
    </>
  );
};

export default BatchUploadTable;
