import { FC, useState, useEffect } from "react";
import ManageTRSM from "../components/modal/manage-trsm";
import TerminalUploadModal from "../components/modal/terminal-upload";
import ListTRSMs from "../components/trsms/list";
import Content from "../layout/content";
import { Button, Errors } from "../lib";
import { FileBtn } from "../components/shared/fileBtn";
import { createTRSMApi } from "../utils/api/trsm-apis";
import { useTranslation } from "react-i18next";

type Props = {};

const TRSMs: FC<Props> = ({}: Props) => {
  const [showTRSMModal, setShowTRSMModal] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [recordsFromFile, setRecordsFromFile] = useState<{
    [key: string]: any;
  }>({});
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [FilesReadingErrors, setFilesReadingErrors] = useState<string[]>([]);

  const toggleFileUploadModal = () => {
    setIsFileUploadModalOpen((prevState) => !prevState);
  };
  const toggleModal = () => {
    setShowTRSMModal((prevState: boolean) => !prevState);
  };

  useEffect(() => {
    if (Object.keys(recordsFromFile).length) {
      setIsFileUploadModalOpen((prevState: boolean) => !prevState);
    }
  }, [recordsFromFile]);

  return (
    <Content
      title={t("TRSMs")}
      isLoading={isLoading}
      //queryBuilder={<QueryBuilder type="trsms" />}
      actionBtns={
        <Button
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
          colorScheme="skyblue"
          onClick={toggleModal}
          label={t("createTRSM") as string}
        />
        // <div className="flex justify-between sm:h-10 md:h-16 lg:h-16 gap-2 ">
        //   {/* <Button
        //     colorScheme="skyblue"
        //     onClick={() => {}}
        //     label="Upload TRSMs"
        //   /> */}
        //   {/* <FileBtn
        //     label={"Upload TRSMs"}
        //     name={"Upload TRSMs"}
        //     classNames={""}
        //     colorScheme={"skyblue"}
        //     size={"lg"}
        //     isRounded
        //     type="trsms"
        //     onClick={() => {
        //       setFilesReadingErrors((prev) => []);
        //     }}
        //     fileType={"text/csv"}
        //     setResult={setRecordsFromFile}
        //     setFilesReadingErrors={setFilesReadingErrors}
        //   /> */}
        // </div>
      }
    >
      <Errors errors={FilesReadingErrors} />
      <ListTRSMs
        setIsLoading={setIsLoading}
        refresh={refreshList}
        setRefresh={setRefreshList}
        isLoading={isLoading}
      />
      <ManageTRSM
        isOpen={showTRSMModal}
        toggleModal={toggleModal}
        refreshList={() => setRefreshList((prevState: boolean) => !prevState)}
      />

      <TerminalUploadModal
        isFileUploadModalOpen={isFileUploadModalOpen}
        toggleModal={toggleFileUploadModal}
        recordsFromFile={recordsFromFile}
        type="trsms"
      />
    </Content>
  );
};

export default TRSMs;
