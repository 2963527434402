import { trackPromise } from "react-promise-tracker";
import Api from ".";

import {
  MappedBatchPayload,
  Batch,
  CreateBatchResponse,
  CurrentState,
  createMerchantObject,
  AssignUserType,
} from "../../types/batch";

export const CreateBatchUploadApi = async ({
  mappedBatchPayload,
  file_name,
}: {
  mappedBatchPayload: MappedBatchPayload;
  file_name: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "create-batch/",
        data: {
          mappedBatchPayload: { mappedBatchPayload },
          file_name,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const getBatchApi = async ({ id }: { id: string }) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "batch/" + id,
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const GetBatchesApi = async ({
  limit = 10,
  page = 1,
}: {
  limit: number;
  page: number;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "batches/",
        params: {
          limit,
          page,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const newMerchantApi = async ({
  merchant_name_arabic,
  merchant_name_english,
  batch_id,
}: {
  merchant_name_arabic: string;
  merchant_name_english: string;
  batch_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "batch-merchant/",
        data: { merchant_name_arabic, merchant_name_english, batch_id },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const newMerchantsApi = async ({
  merchants,
  batch_id,
}: {
  merchants: createMerchantObject[];
  batch_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "batch-merchants/",
        data: { names: merchants, batch_id },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};
export const batchAssignUsersApi = async ({
  users,
  batch_id,
}: {
  users: AssignUserType[];
  batch_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "batch-assign-users/",
        data: { users, batch_id },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};
