import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "../layout/content";
import {
  MerchantResponse,
  MerchantStatus,
  updateMerchantApi,
} from "../utils/api/merchants-apis";
import { Button } from "../lib";
import ViewMerchant from "../components/merchants/view";

type Props = {};
const initialMerchantState: Partial<MerchantResponse> = {
  name: "",
  id: "",
  merchant_bank_id: "",
  created_at: "",
};
const Merchant: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [merchant, setMerchant] =
    useState<Partial<MerchantResponse>>(initialMerchantState);

  const toggleMerchantStatus = (isActive: boolean) => {
    return isActive ? (
      <Button
        className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
        label="Disable"
        colorScheme="red"
        onClick={() => {
          updateMerchantApi({
            id: id as string,
            status: MerchantStatus.deactive,
          });
        }}
      />
    ) : (
      <Button
        className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
        label="Enable"
        colorScheme="default"
        onClick={() =>
          updateMerchantApi({ id: id as string, status: MerchantStatus.active })
        }
      />
    );
  };

  return (
    <Content
      actionBtns={toggleMerchantStatus(merchant?.status == "active")}
      isLoading={isLoading}
      hasBackButton
    >
      <ViewMerchant
        id={id as string}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        merchant={merchant}
        setMerchant={setMerchant}
      />
    </Content>
  );
};

export default Merchant;
