import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content";
import { useTranslation } from "react-i18next";
import { ReconiliationItem } from "../../types/reconciliations";
import { findReconiliationApi } from "../../utils/api/reconcile-apis";
import LoadingSpinner from "../../layout/loading-spinner";
import {
  Button,
  Card,
  formatPrice,
  MerchantsListItem,
  Remove,
  TerminalListItem,
  Tick,
  UserListItem,
} from "../../lib";
import { PaymentSchemeReconciliation } from "./payment-scheme-details";
import ListTransactions from "../../components/transactions/list";
import { DeviceListItem } from "../../lib/list-item/device-list";

type Props = {};

const Reconciliation: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [reconciliation, setReconiliation] = useState<ReconiliationItem>(
    {} as ReconiliationItem
  );

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const findReconiliation = async () => {
    try {
      const reconciliation = await findReconiliationApi({
        id: id as string,
      });
      console.log({ reconciliation });
      setReconiliation(reconciliation);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    findReconiliation();
  }, []);

  return (
    <div className="rounded">
      <Content
        title={t("reconcile")}
        hasBackButton
        actionBtns={
          <a href={reconciliation?.qr_code} target="_blank">
            <Button
              onClick={() => {}}
              label={t("seeReceipt") as string}
              colorScheme="default"
              isRounded={true}
            />
          </a>
        }
      >
        <div>
          <LoadingSpinner isLoading={isLoading} />
          <div className="flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row justify-between items-center mb-4 ">
            <div className="flex flex-row items-center lg:jusitfy-start gap-4 flex-1">
              <span className="font-medium text-2xl lg:text-3xl">
                <span className="font-bold">
                  {formatPrice(Number(reconciliation?.details?.total?.total))}
                </span>
              </span>
              <div className="flex flex-row gap-x-2 items-center">
                {reconciliation?.is_balanced?.value ? (
                  <>
                    <div className="rounded-full bg-green-700">
                      <Tick color="text-white" size={18} />
                    </div>
                    <span className="font-bold">
                      {i18n.language == "ar"
                        ? reconciliation.is_balanced?.label?.arabic
                        : reconciliation.is_balanced?.label?.english}
                    </span>
                  </>
                ) : (
                  <>
                    <div className="rounded-full bg-red-600">
                      <Remove color="text-white" size={18} />
                    </div>
                    <span className="font-bold">
                      {i18n.language == "ar"
                        ? reconciliation.is_balanced?.label?.arabic
                        : reconciliation?.is_balanced?.label?.english}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col items-end flex-1">
              <div className="flex flex-row gap-x-2 lg:gap-x-0 items-center lg:flex-col justify-between">
                <span className="text-md float-left">{t("date&time")}</span>
                <span className="font-bold text-lg">
                  {reconciliation?.date} {reconciliation?.time}
                </span>
              </div>
            </div>
          </div>
          <span className="block text-lg lg:text-2xl font-bold mb-2">
            {t("terminalDetails")}
          </span>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 mb-8">
            {Object.keys(reconciliation?.details || []).map(
              (key: any, index: number) => {
                if (key == "total") return;
                const item =
                  reconciliation?.details[
                    key as keyof typeof reconciliation.details
                  ];
                return (
                  <Card key={index} classNames="px-4 py-4">
                    <div className="flex flex-col">
                      <span className="font-bold text-md capitalize">
                        {t(item?.label?.english)} ({item?.count})
                      </span>
                      <span className="text-2xl font-bold">
                        {formatPrice(item?.total)}
                      </span>
                    </div>
                  </Card>
                );
              }
            )}
          </div>
          <br />
          <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-3 gap-2">
            {reconciliation?.schemes?.map((item: any, index: number) => (
              <PaymentSchemeReconciliation key={index} item={item} />
            ))}
          </div>
        </div>

        {reconciliation.id && (
          <>
            <div className="py-2 mt-2 border-t">
              <h1 className="lg:text-2xl text-lg font-semibold text-gray-900 mt-2">
                Transactions
              </h1>
              <ListTransactions
                refresh={true}
                setIsLoading={setIsLoading}
                reconciliation_id={reconciliation.id}
                limit={10}
              />
            </div>

            <div className="py-2 mt-2 border-t grid grid-cols-1 lg:grid-cols-2 gap-2 mt-6">
              <div className="lg:col-span-2">
                <TerminalListItem
                  title={t("terminal")}
                  role="client"
                  item={reconciliation.terminal}
                  onClick={(terminal) => navigate("/terminals/" + terminal.id)}
                />
              </div>
              <div className="col-span-2">
                <MerchantsListItem
                  title={t("merchant")}
                  role="client"
                  item={reconciliation.merchant}
                  onClick={(merchant) => navigate("/merchants/" + merchant.id)}
                />
              </div>
              <div className="">
                <UserListItem
                  title={t("user")}
                  role="client"
                  item={reconciliation.user}
                />
              </div>
              <div className="">
                <DeviceListItem
                  title={t("device")}
                  role="client"
                  item={reconciliation?.device}
                />
              </div>
            </div>
          </>
        )}
      </Content>
    </div>
  );
};

export default Reconciliation;
