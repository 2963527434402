import React, { FC, useState } from "react";
import { Button, Modal } from "../../lib";
import { ReactComponent as File } from "../../assets/file.svg";
import * as PapaParse from "papaparse";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ParsedFileData } from "../../types/batch";

type Props = {
  close: () => void;
  isOpen: boolean;
};

const UploadBatchModal: FC<Props> = ({ close, isOpen }) => {
  const [dataArray, setDataArray] = useState<ParsedFileData[]>([]);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fileName, setFileName] = useState("");

  const handleFileButtonClick = () => setDataArray([]);

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("uploads/mapper", { state: { dataArray, file_name: fileName } });
  };

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return;
    let file = e.target.files[0];
    let reader = new FileReader();

    reader.onload = function (e) {
      let workbook = XLSX.read(e.target?.result, {
        raw: true,
      });
      const dataArray: ParsedFileData[] = [];
      const csvData = PapaParse.parse(
        XLSX.utils.sheet_to_csv(workbook.Sheets["Sheet1"]) as string,
        { delimiter: "," }
      );

      csvData.data.map((row: any, index) => {
        if (index == 0) {
          row.forEach((header: string, innerIndex: number) => {
            dataArray.push({
              headerFromFile: header.trim(),
              index: innerIndex,
              data: [],
            });
          });
        } else {
          row.forEach((value: string, innerIndex: number) => {
            dataArray
              .find((i) => i.index == innerIndex)
              ?.data.push(value.trim());
          });
        }
      });
      setDataArray(dataArray);
    };
    reader.readAsArrayBuffer(file);
    setFileName(file?.name);
  }

  return (
    <Modal title="" subTitle="" icon={File} isOpen={isOpen} close={close}>
      <form className="flex items-center flex-col">
        <label
          htmlFor={"FileBtn"}
          className={
            "file-input cursor-pointer w-full my-2 border border-transparent font-bold shadow-sm text-center flex flex-row items-center gap-x-2 justify-center rounded-md "
          }
        >
          <input
            name="FileBtn"
            type="file"
            accept={
              "text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
            }
            className={`w-full`}
            onChange={handleFileChange}
            onClick={handleFileButtonClick}
          />
        </label>
        <Button
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
          onClick={handleSubmit}
          label={t("Submit") as string}
          isRounded={false}
          colorScheme={"black"}
          isDisabled={dataArray.length <= 0}
        />
      </form>
    </Modal>
  );
};

export default UploadBatchModal;
