import { useTranslation } from "react-i18next";
import { usePromiseTracker } from "react-promise-tracker";
import Content from "../../layout/content";
import { Button } from "../../lib";
import { ReactComponent as UploadIcon } from "../../assets/upload.svg";
import { useState } from "react";
import UploadBatchModal from "../../components/modal/upload-batch-modal";
import ListBatches from "../../components/batches/list";

const BatchUpload = () => {
  const { promiseInProgress: isLoading } = usePromiseTracker();
  const { t } = useTranslation();
  const [isBatchUploadModalOpen, setIsBatchUploadModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  return (
    <Content
      actionBtns={
        <Button
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
          onClick={() => setIsBatchUploadModalOpen(true)}
          icon={<UploadIcon />}
          colorScheme={"skyblue"}
          label={t("startNewBatch") as string}
        />
      }
      isLoading={isLoading}
      hasBackButton
      title={t("batchUpload")}
    >
      <ListBatches
        refresh={refresh}
        setRefresh={setRefresh}
        setIsLoading={() => {}}
      />
      <UploadBatchModal
        isOpen={isBatchUploadModalOpen}
        close={() => setIsBatchUploadModalOpen(false)}
      />
    </Content>
  );
};
export default BatchUpload;
