import { Filters } from ".";

export const ReconciliationsFilters: Filters[] = [
  {
    header: "All",
    key: "all",
    value: "all",
  },
  {
    header: "Balanced",
    key: "isBalanced",
    value: 1,
  },
  {
    header: "Not Balanced",
    key: "isBalanced",
    value: 0,
  },
];
