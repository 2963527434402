import { FC } from "react";
import { mergeClassNames } from "../utils";

export interface FeedbackType {
  message: string;
  colorScheme: "green" | "red";
}

const feedbackColor: { [key: string]: string } = {
  green: "text-green-700 bg-green-50",
  red: "text-red-700 bg-red-50",
};

export const Feedback: FC<FeedbackType> = ({ message, colorScheme }) => {
  return (
    <div
      className={mergeClassNames(
        "rounded-md p-2 mt-2 list-disc gap-y-1 text-sm",
        message ? "" : "hidden",
        feedbackColor[colorScheme]
      )}
    >
      <div className="flex flex-row">{message}</div>
    </div>
  );
};
