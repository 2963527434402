import { FC, useContext, useEffect } from "react";
import { Routes as RoutesContainer, Route, Navigate } from "react-router-dom";
import UserContext, { UserContextType } from "../../contexts/UserContext";
import Container from "../../layout/container";
import PublicContainer from "../../layout/public-container";
import Login from "../../pages/Login";
import Redirect from "../../pages/Redirect";
import Transactions from "../../pages/Transactions";
import Merchants from "../../pages/Merchants";
import Merchant from "../../pages/Merchant";
import Terminals from "../../pages/Terminals";
import TRSMs from "../../pages/TRSMs";
import Users from "../../pages/Users";
import Admins from "../../pages/Admins";
import Reconciliation from "../../pages/Reconciliation";
import Reconciliations from "../../pages/Reconciliations";
import Terminal from "../../pages/Terminal";
import Transaction from "../../pages/Transaction";
import ResetPassword from "../../pages/ResetPassword";
import UpdatePassword from "../../pages/UpdatePassword";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ScrollToTop } from "./scroll-to-top";
import ResetPasswordMessageSent from "../../pages/ResetPasswordMessageSent";
import { bootIntercom } from "../../boot-intercom";
import PublicLayoutContainer from "../../pages/PublicLayout";
import SignUp from "../../pages/SignUp";
import Verify from "../../pages/Verify";
import SelectPlatform from "../../pages/SelectPlatform";
import Apps from "../../pages/Apps";
import App from "../../pages/App";
import Credentials from "../../pages/CredentialsPage";
import CreateOrgnizationAccount from "../../pages/CreateOrgnizationAccount";
// import Webhooks from "../../pages/Webhooks";
import { LogView } from "../../components/webhooks/log-view";
import Webhooks from "../../pages/Webhooks";
import UpdateMobile from "../../pages/UpdateMobile";
import BatchUploads from "../../pages/Batch-Upload";
import Workspace from "../../pages/Batch-Upload/Workspace";
import Mapper from "../../pages/Batch-Upload/Mapper";

const Routes: FC = ({}) => {
  const { hasToken } = useContext(UserContext) as UserContextType;
  useEffect(() => {
    bootIntercom();
  }, []);

  return (
    <ScrollToTop>
      {hasToken() ? (
        <Container>
          <RoutesContainer>
            <Route path="/credentials-page" element={<Credentials />} />
            <Route path="/apps">
              <Route index element={<Apps />} />
              <Route path=":id" element={<App />} />
            </Route>
            <Route path="/merchants">
              <Route index element={<Merchants />} />
              <Route path=":id" element={<Merchant />} />
            </Route>
            <Route path="transactions">
              <Route index element={<Transactions />} />
              <Route path=":id" element={<Transaction />} />
            </Route>
            <Route path="terminals">
              <Route index element={<Terminals />} />
              <Route path="batches">
                <Route index element={<BatchUploads />} />
                <Route path="uploads/mapper" element={<Mapper />} />
                <Route path="uploads/workspace/:id" element={<Workspace />} />
              </Route>
              <Route path=":id" element={<Terminal />} />
            </Route>
            <Route path="/trsms" element={<TRSMs />} />
            <Route path="reconciliations">
              <Route index element={<Reconciliations />} />
              <Route path=":id" element={<Reconciliation />} />
            </Route>
            <Route path="/users">
              <Route index element={<Users />} />
            </Route>
            <Route path="/admins">
              <Route index element={<Admins />} />
            </Route>
            <Route path="/webhooks" element={<Webhooks />}>
              <Route path=":id" element={<LogView />} />
            </Route>
            <Route path="*" element={<Navigate to={"/merchants"} />} />
          </RoutesContainer>
        </Container>
      ) : (
        <GoogleReCaptchaProvider
          reCaptchaKey="6LfETr0hAAAAAGhTpXh9z0SiBBe2Qcm3VUawtVCq"
          language="en"
          scriptProps={{
            async: true,
            defer: false,
            appendTo: "head",
          }}
        >
          <PublicContainer>
            <RoutesContainer>
              <Route path="/" element={<PublicLayoutContainer />}>
                <Route index element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/update-password" element={<UpdatePassword />} />
                <Route
                  path="/message-sent"
                  element={<ResetPasswordMessageSent />}
                />
                <Route path="/update-mobile" element={<UpdateMobile />} />
                <Route path="/verify" element={<Verify />} />
                {process.env.REACT_APP_ONBOARDING_ON === "true" ? (
                  <>
                    <Route path="/signup" element={<SignUp />} />
                    <Route
                      path="/create-orgnization-account"
                      element={<CreateOrgnizationAccount />}
                    />
                    <Route
                      path="/select-platform"
                      element={<SelectPlatform />}
                    />
                  </>
                ) : null}
                <Route path="*" element={<Redirect />} />
              </Route>
            </RoutesContainer>
          </PublicContainer>
        </GoogleReCaptchaProvider>
      )}
    </ScrollToTop>
  );
};

export default Routes;
