import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TerminalsFilters } from "../components/filters/terminals-filters";
import ManageTerminals from "../components/modal/manage-terminals";
import TerminalUploadModal from "../components/modal/terminal-upload";
import ListTerminals from "../components/terminals/list";
import Content from "../layout/content";
import { Button } from "../lib";
import {
  listMerchantsApi,
  MerchantResponse,
} from "../utils/api/merchants-apis";
import { listTRSMsApi, ListTRSMResponseApi } from "../utils/api/trsm-apis";

type Props = {};

const Terminals: FC<Props> = ({}: Props) => {
  const [showTerminalsModal, setShowTerminalsModal] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const { t } = useTranslation();

  const [trsms, setTrsms] = useState<ListTRSMResponseApi>(
    {} as ListTRSMResponseApi
  );
  const [recordsFromFile, setRecordsFromFile] = useState<{
    [key: string]: any;
  }>({});
  const [FilesReadingErrors, setFilesReadingErrors] = useState<string[]>([]);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const navigate = useNavigate();
 
  const toggleFileUploadModal = () => {
    setIsFileUploadModalOpen((prevState) => !prevState);
  };
  const fetchTRSMs = async ({
    page,
    search,
  }: {
    page?: number;
    search?: string;
  }) => {
    let TRSMSResponse = await listTRSMsApi({
      page,
      limit: 5,
      is_not_assigned: true,
      search,
    });
    setTrsms(TRSMSResponse);
  };

  useEffect(() => {
    if (Object.keys(recordsFromFile).length) {
      setIsFileUploadModalOpen((prevState: boolean) => !prevState);
    }
  }, [recordsFromFile]);

  const toggleModal = () =>
    setShowTerminalsModal((prevState: boolean) => !prevState);


  return (
    <Content
      title={t("terminals")}
      isLoading={isLoading}
      actionBtns={
        <div className="flex justify-between gap-2">
          <Button
            colorScheme="skyblue"
            className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm min-w-max"}
            onClick={toggleModal}
            label={t("createTerminal") as string}
            isRounded={true}
          />
          <Button
            colorScheme="skyblue"
            className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm min-w-max"}
            onClick={() => navigate("batches")}
            label={t("batchUpload") as string}
            isRounded={true}
          />
        </div>
      }
      tabs={TerminalsFilters}
      setActiveTab={setActiveTab}
      activeTab={activeTab}
    >
      <ListTerminals
        refresh={refreshList}
        setRefresh={setRefreshList}
        setIsLoading={setIsLoading}
        activeTab={activeTab}
      />
      <ManageTerminals
        isOpen={showTerminalsModal}
        toggleModal={toggleModal}
        refresh={() => setRefreshList((prevState: boolean) => !prevState)}
      />

      <TerminalUploadModal
        isFileUploadModalOpen={isFileUploadModalOpen}
        toggleModal={toggleFileUploadModal}
        recordsFromFile={recordsFromFile}
        type="terminals"
      />
    </Content>
  );
};

export default Terminals;
