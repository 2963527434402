import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WebhooksFilters } from "../components/filters/webhooks-filters";
import ManageWebhookModal from "../components/modal/manage-webhook";
import ListWebhooks from "../components/webhooks/list";
import ListLogs from "../components/webhooks/list-logs";
import Content from "../layout/content";
import { Button } from "../lib";
import { listEventsApi } from "../utils/api/webhooks-apis";

type Props = {};

export const eventReadableName = (name: string) =>
  name
    .split(".")
    .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
    .join(" ");

const Webhooks: FC<Props> = ({}: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<"All" | "Logs">("All");
  const [toggleModal, setToggleModal] = useState(false);
  const [webhook, setWebhook] = useState<{ [key: string]: any }>({});
  const [refreshWebhook, setRefreshWebhook] = useState(false);
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  const toggleManageWebhookModal = () => setToggleModal((prev) => !prev);

  const changeTab = (tab: "All" | "Logs") => {
    if (tab == "All") navigate("/webhooks");
    setActiveTab(tab);
  };

  const listEvents = async () => {
    try {
      let response = await listEventsApi();
      setEvents(response);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    listEvents();
  }, [refreshWebhook]);

  return (
    <Content
      tabs={WebhooksFilters}
      title={t("webhooks")}
      activeTab={activeTab}
      setActiveTab={changeTab}
      isLoading={isLoading}
      actionBtns={
        <Button
          onClick={toggleManageWebhookModal}
          label={"+"}
          colorScheme="black"
          isRounded={false}
          className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm "}
        />
      }
    >
      {activeTab == "All" && (
        <ListWebhooks
          setIsLoading={setIsLoading}
          refresh={refreshWebhook}
          toggleModal={setToggleModal}
          setWebhook={setWebhook}
        />
      )}{" "}
      {activeTab == "Logs" && <ListLogs setIsLoading={setIsLoading} />}
      <ManageWebhookModal
        toggleModal={toggleManageWebhookModal}
        isOpen={toggleModal}
        setRefresh={setRefreshWebhook}
        webhook={webhook as any}
        setWebhook={setWebhook}
        events={events}
      />
    </Content>
  );
};

export const WebhookMethod = ({ method }: { method: string }) => {
  const methodStyle: any = {
    get: "bg-green-200 text-green-700 ",
    put: "bg-yellow-200 text-yellow-700 ",
    post: "bg-blue-200 text-blue-700 ",
    patch: "bg-gray-200 text-gray-700 ",
    delete: "bg-red-200 text-red-700 ",
  };
  return (
    <div
      className={`font-mono rounded w-full text-center py-0.5 px-1 lg:px-2 ${
        methodStyle[method.toLowerCase()]
      }`}
    >
      {method}
    </div>
  );
};

export const WebhookURL = ({ url }: { url: string }) => (
  <div className="w-full font-mono break-words">{url}</div>
);

export const WebhookEvent = ({ event }: { event: string }) => (
  <div className="font-mono break-words">{event}</div>
);

export const WebhookStatus = ({ status }: { status: string }) => {
  const statusStyle: any = {
    2: "bg-green-200 text-green-700  ",
    4: "bg-yellow-200 text-yellow-700",
    5: "bg-red-200 text-red-700",
  };
  return (
    <div
      className={`font-mono rounded px-2 xl:px-4 py-0.5 text-center ${
        statusStyle[status.slice(0, 1)] || "bg-red-200 text-red-700"
      }`}
    >
      {status}
    </div>
  );
};

export default Webhooks;
