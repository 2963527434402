import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MerchantsFilters } from "../components/filters/merchants-filters";
import ListMerchants from "../components/merchants/list";
import {
  initialNewMerchantState,
  ManageMerchant,
  ManageMerchantModal,
} from "../components/modal/manage-merchant";
import Content from "../layout/content";
import { Button } from "../lib";

type Props = {};

const Merchants: FC<Props> = ({}: Props) => {
  const [refreshList, setRefreshList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const { t } = useTranslation();
  const [newMerchant, setNewMerchant] = useState<ManageMerchant>(
    initialNewMerchantState
  );
  const [isCreateMerchantModalOpen, setIsCreateMerchantModalOpen] =
    useState(false);

  const toggleCreateMerchantModal = () =>
    setIsCreateMerchantModalOpen((prevState: boolean) => !prevState);

  return (
    <>
      <Content
        title={t("merchants")}
        isLoading={isLoading}
        tabs={MerchantsFilters}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        actionBtns={
          <div id="test-create-merchant-button">
            <Button
              className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
              onClick={toggleCreateMerchantModal}
              label={t("createNewMerchant") as string}
              colorScheme="skyblue"
              isRounded={true}
            />
          </div>
        }
      >
        <ListMerchants
          setIsLoading={setIsLoading}
          refresh={refreshList}
          setRefresh={setRefreshList}
          activeTab={activeTab}
        />

        <ManageMerchantModal
          isOpen={isCreateMerchantModalOpen}
          toggleModal={toggleCreateMerchantModal}
          newMerchant={newMerchant}
          setNewMerchant={setNewMerchant}
          refreshMerchant={setRefreshList}
        />
      </Content>
    </>
  );
};

export default Merchants;
