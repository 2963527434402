import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../layout/loading-spinner";
import { AppListItem, EmptyState, GroupedList } from "../../lib";
import { listAppsApi } from "../../utils/api/apps-api";

type Props = {
  refresh: boolean;
  setRefresh: (refresh: boolean) => any;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  limit?: number;
};

type App = {
  pages: number;
  clientPackages: ClientPackage[];
};

type ClientPackage = {
  app_name: string;
  id: string;
  name: string;
  type: string;
};

const ListApps = ({ setIsLoading, refresh, isLoading, limit = 10 }: Props) => {
  const [apps, setApps] = useState<App[]>();
  const [pages, setPages] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToApp = (item_id: string) => {
    navigate(item_id);
  };

  const fetchApps = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await listAppsApi({
        page,
        limit,
      });
      setPages(response.pages);
      setApps(response.clientPackages);
      setIsLoading(false);
    } catch (e) {
      console.log({ e });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
  }, [refresh]);

  return apps?.length ? (
    <div>
      <LoadingSpinner isLoading={isLoading} />
      <GroupedList
        pages={{
          selectPage: (page) => fetchApps(page),
          total: pages,
        }}
        data={apps}
        groupBy={{
          key: "created_at",
          isDate: true,
        }}
        component={AppListItem}
        onItemClick={(item) => goToApp(item.id)}
        role={"client"}
      />
    </div>
  ) : (
    <EmptyState title={t("itemNotFound") as string} />
  );
};

export default ListApps;
