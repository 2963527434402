import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "../layout/content";
import ViewTerminal from "../components/terminals/view";
type Props = {};

const Terminal: FC<Props> = ({}: Props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Content hasBackButton isLoading={isLoading}>
      <ViewTerminal
        id={id as string}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </Content>
  );
};

export default Terminal;
