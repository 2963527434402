import Api, { List } from ".";
import { dateRange } from "../../components/query-builder/date-picker";
import { trackPromise } from "react-promise-tracker";
export enum MerchantStatus {
  active = "active",
  deactive = "deactive",
}

export interface MerchantResponse {
  id: string;
  name: string;
  name_ar: string;
  merchant_bank_id: string;
  status: "active" | "deactive";
  created_at: string;
  auto_reconcile_total: {
    enabled: number;
    disabled: number;
  };
}
interface Merchants extends List {
  filter?: { [key: string]: any };
  queryParams?: { [key: string]: any };
  dateRange?: dateRange;
}

interface Merchant {
  id?: string;
  name?: string;
  card_acceptor_id_code: string;
  card_acceptor_name_and_location: string;
  merchant_category_code: string;
  receipt_address: string;
}

interface UpdateMerchant {
  id: string;
  status?: MerchantStatus;
  name?: string;
  card_acceptor_id_code?: string;
  card_acceptor_name_and_location?: string;
  merchant_category_code?: string;
  receipt_address?: string;
}

export const listMerchantsApi = async ({
  limit = 30,
  page = 1,
  filter,
  queryParams,
  dateRange,
}: Merchants) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "merchants/",
        params: {
          limit,
          page,
          ...filter,
          // queryParams,
          // dateRange,
        },
      })
    );
    return response.data ;
  } catch (e: any) {
    throw e;
  }
};

export const findMerchantApi = async ({ id }: { id: string }) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "merchants/" + id,
      })
    );
    return response.data as MerchantResponse;
  } catch (e: any) {
    throw e;
  }
};

export const newMerchantApi = async ({
  name,
  card_acceptor_id_code,
  card_acceptor_name_and_location,
  merchant_category_code,
  receipt_address,
}: Merchant) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "merchants/",
        data: {
          name,
          card_acceptor_id_code,
          card_acceptor_name_and_location,
          merchant_category_code,
          receipt_address,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

// todo -- remove if not used
//
export const newMerchantsApi = async (merchants: {
  [key: string]: { name: string; name_ar: string };
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "merchants-batch/",
        data: { merchants },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const updateMerchantApi = async ({
  id,
  status,
  name,
  card_acceptor_id_code,
  card_acceptor_name_and_location,
  merchant_category_code,
  receipt_address,
}: UpdateMerchant) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "merchants/" + id,
        data: {
          status,
          name,
          card_acceptor_id_code,
          card_acceptor_name_and_location,
          merchant_category_code,
          receipt_address,
        },
      })
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getMerchantsNames = async ({
  merchant_ids,
}: {
  merchant_ids: string[];
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "merchantsNames/",
        data: {
          merchant_ids: merchant_ids,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const linkMerchantApi = async ({
  merchant_id,
  merchant_name,
  batch_id,
}: {
  merchant_id: string;
  merchant_name: string;
  batch_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "merchant-link/",
        data: {
          merchant_id,
          merchant_name,
          batch_id,
        },
      })
    );

    return response.data;
  } catch (e: any) {
    throw e;
  }
};
