import { FC, ReactNode } from "react";
import { ArrowLeft, ArrowRight, Checked, XIcon } from "../icons";
import { Card } from "../shared";
import { ReconciliationListItemType } from "../types/reconciliation";
import { useTranslation } from "react-i18next";

export const ReconciliationListItem: FC<ReconciliationListItemType> = ({
  role,
  onClick,
  item,
}) => {
  const { i18n, t } = useTranslation();
  const statusIcon: { [key: string]: ReactNode } = {
    1: (
      <div className="flex flex-row gap-1 items-center bg-green-200 text-green-600 rounded-full p-1 px-2 font-semibold text-xs">
        <Checked size={16} color="text-green-600" />
        <span>{t("balanced") as string}</span>
      </div>
    ),
    0: (
      <div className="flex flex-row gap-1 items-center bg-red-200 text-red-600 rounded-full p-1 px-2 font-semibold text-xs">
        <XIcon size={16} color="text-red-600" />
        <span>{t("unBalanced") as string}</span>
      </div>
    ),
  };
  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full mt-3" onClick={onClick}>
      <div className="flex  items-center justify-between gap-2">
        <div className="">{statusIcon[Number(item?.is_balanced)]}</div>

        <div className="md:w-1/12 flex flex-col text-sm lg:text-base capitalize">
          <span className="font-bold whitespace-nowrap">
            {item?.total_value}{" "}
            <small className="text-xs font-normal">{item?.currency_code}</small>
          </span>
          <span className="text-xs">{t("total")}</span>
        </div>

        <div
          className={`${
            item?.merchant ?? "invisible"
          } md:w-1/12 hidden md:flex flex-col`}
        >
          <small>{t("merchant")}</small>
          <span className="font-bold text-xs whitespace-nowrap">
            {item?.merchant?.name ?? "---"}
          </span>
        </div>

        <div className={`hidden md:flex flex-col ${item.user ?? " invisible"}`}>
          <small>{t("user")}</small>
          <span className="font-bold text-xs">{`${item?.user?.name} (${item?.user?.mobile})`}</span>
        </div>
        <div className="hidden sm:flex flex-col">
          <small>{t("terminal")}</small>
          <span className="font-bold text-xs">
            {item.card_acceptor_terminal_id}
          </span>
        </div>
        <div className="flex flex-col text-sm lg:text-base capitalize">
          <span className="font-bold whitespace-nowrap">
            {item?.total_value}{" "}
            <small className="text-xs font-normal">{item.currency_code}</small>
          </span>
          <span className="text-xs">
            {t("count")} {item?.total_count}
          </span>
        </div>
        <div className="hidden lg:flex flex-col ">
          <span className="font-bold">{item?.time}</span>
          <span className="text-xs">
            STAN : {item?.system_trace_audit_number}
          </span>
        </div>
        <div className="flex-initial">
          {i18n.language == "ar" ? <ArrowLeft /> : <ArrowRight />}
        </div>
      </div>
    </Card>
  );
};
