export default {
  get: (key: string) => {
    let data = localStorage.getItem(`@${key}`);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        if (e instanceof SyntaxError) {
          return data;
        }
      }
    }
  },
  set: (key: string, value: any) => {
    return localStorage.setItem(
      `@${key}`,
      typeof value == "object" ? JSON.stringify(value) : value
    );
  },

  remove: (key: string) => {
    return localStorage.removeItem(`@${key}`);
  },
};
