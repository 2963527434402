import { FC, ReactElement, ReactNode, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Filters } from "../components/filters";
import BackButton from "../components/shared/back-button";

import LoadingSpinner from "./loading-spinner";

type Props = {
  children: ReactNode;
  actionBtns?: ReactElement;
  isLoading?: boolean;
  activeTab?: string;
  tabs?: Filters[];
  setActiveTab?: any;
  queryBuilder?: ReactNode;
  title?: string;
  hasBackButton?: boolean;
};

const Content: FC<Props> = ({
  children,
  title,
  actionBtns,
  isLoading = false,
  tabs,
  setActiveTab,
  queryBuilder,
  activeTab = "All",
  hasBackButton = false,
}: Props) => {
  const { t } = useTranslation();
  function camelCase(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }
  return (
    <div>
      {hasBackButton ? (
        <div className="flex gap-3 my-2 items-center mx-2 lg:mx-0">
          <BackButton />
          <div className="text-sm font-bold">{t("goBack") as string} </div>
        </div>
      ) : null}
      <LoadingSpinner isLoading={isLoading} />
      <div className="flex flex-col bg-white  xl:px-0 my-4 rounded-lg ">
        {title?.length ? (
          <>
            <div className="px-4 py-6 sm:px-6 border-b">
              <div className="flex flex-row items-center justify-between">
                <div className="flex gap-2 items-center">
                  <h1 className="lg:text-2xl text-lg font-semibold text-gray-900">
                    {title}
                  </h1>
                  <div className="hidden pl-4 lg:flex gap-4 flex-wrap">
                    {tabs?.map((tab, index) => (
                      <div
                        key={index}
                        className={
                          activeTab == tab.header
                            ? "border-b-3 border-primary"
                            : ""
                        }
                      >
                        <button
                          value={tab.header}
                          onClick={() => setActiveTab(tab.header as string)}
                          className={"px-4 py-2 font-semibold "}
                        >
                          {t(camelCase(tab.header)) as string}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-initial">{actionBtns}</div>
              </div>
              {tabs?.length ? (
                <div className="lg:hidden pt-4">
                  <div className="grid grid-cols-3 gap-4">
                    {tabs?.map((tab, index) => (
                      <button
                        value={tab.header}
                        key={index}
                        onClick={() => setActiveTab(tab.header as string)}
                        className={`p-2 font-semibold text-xs ${
                          activeTab == tab.header
                            ? "border-b-3 border-blue-700"
                            : ""
                        }`}
                      >
                        {tab.header}
                      </button>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {queryBuilder}
        <div className="px-4 sm:px-6">
          {/* Replace with your content */}
          <div className="py-4">{children}</div>
          {/* /End replace */}
        </div>
      </div>
    </div>
  );
};

export default Content;
