import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TerminalIcon } from "../icons";
import { Badge } from "../shared";
import { Card } from "../shared";
import { BadgeType } from "../shared/badge";
import { TRSMListItemType } from "../types/trsm";

export const TRSMLIstItem: FC<TRSMListItemType> = ({ role, onClick, item }) => {
  const { t } = useTranslation();

  const trsmBadge: BadgeType = item?.terminal?.id
    ? { label: t("registered"), color: "green" }
    : { label: t("notRegistered"), color: "red" };
  return (
    <Card classNames="lg:py-4 lg:px-4 p-2 w-full mt-3" onClick={onClick}>
      <div className="flex flex-row items-center">
        <TerminalIcon containerClassNames="pr-4" />
        <div className="flex flex-col flex-1 text-sm lg:text-base">
          <span className="font-bold">{item?.code || "--"}</span>
          <span className="text-gray-400 text-sm">
            {item?.vendor_id || "--"}
          </span>
        </div>
        <div className="flex-initial">
          <Badge {...trsmBadge} isRounded={true} />
        </div>
      </div>
    </Card>
  );
};
