import { trackPromise } from "react-promise-tracker";
import Api, { List } from ".";
import { TerminalConnection } from "../../components/terminal-connections/list";

interface Terminals extends List {
  filter: { [key: string]: any };
  merchant_id?: string;
}

interface ToggleTerminalAutoReconcile {
  isEnabled: boolean;
  terminal_id: string;
}

interface ToggleMerchantTerminalsAutoReconcile {
  isEnabled: boolean;
  merchant_id: string;
}

interface AssignTerminal {
  id: string;
  mobile: string;
  sendSMS: boolean;
}
interface UnassignTerminal {
  id: string;
}

interface DisconnectTerminal {
  terminal_id: string;
  device_id: string;
}

// interface Terminal{
//   id: string;
// }

interface TerminalConnectionResponse {
  connections: TerminalConnection[];
  pages: number;
}

interface Terminal {
  id?: string;
  reference_terminal_id?: string;
  card_acceptor_terminal_id?: string;
  merchant_id?: string;
  merchant_name?: string;
  card_acceptor_id_code?: string;
  merchant_category_code: string;
  merchant_receipt_address: string;
  card_acceptor_name_and_location: string;
}
interface TerminalResponse {
  terminal: {
    id: string;
    name?: string;
    tid?: string;
    user: {
      name: string;
    };
    client?: {
      name: string;
    };
    merchant: {
      name: string;
    };
    is_assigned_to_user: boolean;
    is_connected: boolean;
  };
  profile: any;
}

export const listTerminalsApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchant_id,
}: Terminals) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals",
        params: {
          limit,
          page,
          ...filter,
          merchant_id,
        },
      })
    );
    return response.data as { pages: any; terminals: TerminalResponse[] };
  } catch (e: any) {
    throw e;
  }
};

export const findTerminalApi = async ({ id }: { id: string }) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "terminals/" + id,
      })
    );
    return response.data as TerminalResponse;
  } catch (e: any) {
    throw e;
  }
};

export const createTerminalApi = async ({
  merchant_id,
  merchant_name,
  merchant_category_code,
  card_acceptor_id_code,
  merchant_receipt_address,
  card_acceptor_name_and_location,
  card_acceptor_terminal_id,
}: Terminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "terminals",
        data: {
          merchant_id,
          merchant_name,
          merchant_category_code,
          card_acceptor_id_code,
          merchant_receipt_address,
          card_acceptor_name_and_location,
          card_acceptor_terminal_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const updateTerminalApi = async ({
  id,
  reference_terminal_id,
  merchant_category_code,
  merchant_receipt_address,
  card_acceptor_name_and_location,
}: Terminal) => {
  try {
    console.log("llll");

    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/update",
        data: {
          id,
          reference_terminal_id,
          merchant_category_code,
          merchant_receipt_address,
          card_acceptor_name_and_location,
        },
      })
    );
    console.log({ response });

    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};

export const assginTerminalApi = async ({
  id,
  mobile,
  sendSMS,
}: AssignTerminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/connect",
        data: {
          id,
          mobile,
          sendSMS,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const unAssginTerminalApi = async ({ id }: UnassignTerminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/unassign",
        data: {
          terminal_id: id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const disconnectTerminalApi = async ({
  terminal_id,
  device_id,
}: DisconnectTerminal) => {
  try {
    const response = await trackPromise(
      Api({
        method: "put",
        url: "terminals/disconnect",
        data: {
          terminal_id,
          device_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const fileDownloadApi = async ({
  id,
  isFull,
}: {
  id: string;
  isFull: boolean;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "patch",
        url: "terminal/configuration",
        data: {
          terminalId: id,
          isFull,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const toggleAutoReconcileApi = async ({
  terminal_id,
  isEnabled,
}: ToggleTerminalAutoReconcile) => {
  try {
    const response = await Api({
      method: "PUT",
      url: "terminals/toggle-auto-reconcile",
      data: {
        terminal_id,
        is_enabled: isEnabled,
      },
    });
    return response.data;
  } catch (e) {
    console.log({ e });
    throw e;
  }
};

export const toggleMerchantTerminalsApi = async ({
  isEnabled,
  merchant_id,
}: ToggleMerchantTerminalsAutoReconcile) => {
  try {
    const response = await Api({
      method: "PUT",
      url: "terminals/toggle-merchant-terminals-auto-reconcile",
      data: {
        merchant_id,
        is_enabled: isEnabled,
      },
    });
    return response.data;
  } catch (e) {
    console.log({ e });
  }
};

export const clientCreateAndRegisterTrsmAndTerminalApi = async ({
  trsms,
  batch_id,
}: {
  trsms: any[];
  batch_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "terminal-create-and-register",
        data: {
          trsms,
          batch_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listTerminalConnectionsApi = async ({
  terminal_id,
  page = 1,
  limit = 30,
}: {
  terminal_id?: string;
  page: number;
  limit: number;
}) => {
  try {
    const response = await Api({
      method: "GET",
      url: "terminals-connections",
      params: {
        terminal_id,
        page,
        limit,
      },
    });
    return response.data as TerminalConnectionResponse;
  } catch (e) {
    throw e;
  }
};
