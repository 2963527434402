import React, { FC } from "react";
import { MerchantIcon } from "../icons";
import { Card } from "../shared";
import { ClientListItemType } from "../types/client";

export const ClientListItem: FC<ClientListItemType> = ({
  role,
  title,
  onClick,
  item,
}) => {
  return (
    <div className="mt-3">
      {title ? (
        <span className="text-lg font-bold text-gray-600">{title}</span>
      ) : null}
      <Card classNames="lg:py-4 lg:px-4 p-2 w-full" onClick={onClick}>
        <div className="flex flex-row items-center">
          <MerchantIcon containerClassNames="pr-4" />
          <div className="flex flex-col flex-1">
            <span className="font-bold text-sm lg:text-base">
              {item?.name || "--"}
            </span>
            <span className="text-gray-600 text-xs">#{item?.id}</span>
          </div>
        </div>
      </Card>
    </div>
  );
};
