import { FC } from "react";
import { IconType } from "./types";

export const Invite: FC<IconType> = ({
  containerClassNames = "",
  size = 24,
  color = "text-black",
}) => {
  return (
    <div className={"lg:p-2 " + containerClassNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        className={"fill-current " + color}
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z" />
      </svg>
    </div>
  );
};
