import { Filters } from ".";

export const TransactionsFilters: Filters[] = [
  // {
  //   header: "All",
  //   key: "all",
  //   value: "all",
  // },
  // {
  //   header: "Refunds",
  //   key: "type",
  //   value: "20",
  // },
  // {
  //   header: "Approved",
  //   key: "type",
  //   value: "00",
  // },
  // {
  //   header: "Rejected",
  //   key: "status",
  //   value: "rejected",
  // },
  // {
  //   header: "Reversed",
  //   key: "status",
  //   value: "reversed",
  // },
];
