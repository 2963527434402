import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createFilterObject, MerchantsFilters } from "../components/filters";
import { UsersFilters } from "../components/filters/users-filters";
import ManageUserModal, { ManageUser } from "../components/modal/manage-user";
import ListUsers from "../components/users/list";
import Content from "../layout/content";
import { Button } from "../lib";
import {
  listMerchantsApi,
  MerchantResponse,
} from "../utils/api/merchants-apis";

type Props = {};
const Users: FC<Props> = () => {
  const [refreshList, setRefreshList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const { t } = useTranslation();
  const [isManageUserModalOpen, setIsManageUserModalOpen] = useState(false);
  const [merchants, setMerchants] = useState<MerchantResponse[]>(
    {} as MerchantResponse[]
  );
  const [userToBeAdded, setUserToBeAdded] = useState<ManageUser>({
    name: "",
    mobile: "",
    merchant_id: "",
    email: "",
  });

  const close = () => {
    toggleModal();
  };
  const toggleModal = () => {
    setIsManageUserModalOpen((prevState) => !prevState);
  };
  const getMerchants = async () => {
    const response = await listMerchantsApi({
      limit: 10000,
      filter: createFilterObject(activeTab ? activeTab : "", MerchantsFilters),
    });
    setMerchants(response.merchants);
  };

  useEffect(() => {
    getMerchants();
  }, [refreshList]);

  return (
    <>
      <Content
        title={t("users")}
        isLoading={isLoading}
        tabs={UsersFilters}
        setActiveTab={setActiveTab}
        activeTab={t(activeTab)}
        actionBtns={
          <Button
            className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm"}
            label={t("addNewUser") as string}
            onClick={() => toggleModal()}
            colorScheme={"skyblue"}
          />
        }
      >
        <ListUsers
          setIsLoading={setIsLoading}
          refresh={refreshList}
          setRefresh={setRefreshList}
          activeTab={activeTab}
        />
        <ManageUserModal
          isOpen={isManageUserModalOpen}
          setUser={setUserToBeAdded}
          user={userToBeAdded}
          toggleModal={close}
          merchants={merchants}
          setRefreshUsers={setRefreshList}
        />
      </Content>
    </>
  );
};

export default Users;
