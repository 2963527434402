import { FC } from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as HeroImage } from "../assets/hero.svg";

type Props = {};

const PublicLayoutContainer: FC<Props> = () => {
  return (
    <div className="public-container h-full w-full flex bg-white">
      <div className="bg-white h-full w-full md:max-w-xl">
        <Outlet />
      </div>
      <div className="gradient relative overflow-hidden w-full md:flex hidden">
        <HeroImage className="hero-image" />
      </div>
    </div>
  );
};

export default PublicLayoutContainer;
