import Api, { List } from "./";
import { dateRange } from "../../components/query-builder/date-picker";
import { ReconciliationResponse } from "../../types/reconciliations";
import { trackPromise } from "react-promise-tracker";
interface Reconciliations extends List {
  filter: { [key: string]: any };
  merchant_id?: string;
  terminal_id?: string;
  queryParams: { [key: string]: any };
  dateRange?: dateRange;
}

interface Reconcile {
  id: string;
}

interface NewReconcile {
  terminal_ids: string[];
}

export const findReconiliationApi = async ({ id }: Reconcile) => {
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reconciliations/" + id,
      })
    );
    return response.data;
  } catch (e: any) {
    throw e;
  }
};

export const listReconciliationsApi = async ({
  limit = 30,
  page = 1,
  filter,
  merchant_id,
  terminal_id,
  queryParams,
  dateRange,
}: Reconciliations) => {
  let encodedQueryParams = btoa(JSON.stringify(queryParams));
  let encodeddateRange = btoa(JSON.stringify(dateRange));
  try {
    const response = await trackPromise(
      Api({
        method: "get",
        url: "reconciliations/",
        params: {
          limit,
          page,
          ...filter,
          merchant_id,
          terminal_id,
          queryParams: encodedQueryParams,
          dateRange: encodeddateRange,
        },
      })
    );
    return response.data as {
      pages: any;
      receipts: ReconciliationResponse;
    };
  } catch (e: any) {
    throw e;
  }
};

export const reconcileApi = async ({
  terminal_id,
  merchant_id,
}: {
  terminal_id: string;
  merchant_id: string;
}) => {
  try {
    const response = await trackPromise(
      Api({
        method: "post",
        url: "reconcile",
        data: {
          terminal_id,
          merchant_id,
        },
      })
    );
    return response.data;
  } catch (e: any) {
    if (e?.message) throw e.message;
    throw e;
  }
};
