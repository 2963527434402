import { RequiredHeader } from "../../types/batch";

const possibleMerchantHeaders = ["merchant"];
const possibleMerchantArabicHeaders = ["merchant arabic"];
const possibleTRSMHeaders = ["trsm"];
const possibleMobileHeaders = ["user mobile", "mobiles"];
const possibleUserHeaders = ["users name", "user name"];
const possibleTIDHeaders = ["tid"];
const possibleEmailHeaders = ["users email", "emails", "user email"];

export const requiredHeaders: RequiredHeader[] = [
  {
    header: "Merchant Name",
    regex: /^\S+[\s\S]*$/,
    key: "merchant_name_english",
    index: 0,
    autoMappedHeaders: possibleMerchantHeaders,
    is_unique: false,
    is_optional: false,
  },
  {
    header: "Merchant Arabic",
    regex: /^\S+[\s\S]*$/,
    key: "merchant_name_arabic",
    index: 1,
    autoMappedHeaders: possibleMerchantArabicHeaders,
    is_unique: false,
    is_optional: false,
  },
  {
    header: "Trsms",
    regex: /^[0-9A-Fa-f]{6}$/,
    key: "trsms",
    index: 2,
    autoMappedHeaders: possibleTRSMHeaders,
    is_optional: false,
    is_unique: true,
  },
  {
    header: "TID",
    regex: /^[0-9]{16}$/,
    key: "tid",
    index: 3,
    autoMappedHeaders: possibleTIDHeaders,
    is_unique: true,
    is_optional: false,
  },
  {
    header: "User Name",
    regex: /^\S+[\s\S]*$/,
    key: "user_name",
    index: 4,
    autoMappedHeaders: possibleUserHeaders,
    is_unique: false,
    is_optional: false,
  },
  {
    header: "User Mobile",
    regex: /^\+\d{12}$/,
    key: "user_mobile",
    index: 5,
    autoMappedHeaders: possibleMobileHeaders,
    is_unique: false,
    is_optional: false,
  },
  {
    header: "User Email",
    regex: /^[\w\-\.]+@\w+\.\w+$/,
    key: "user_email",
    index: 6,
    autoMappedHeaders: possibleEmailHeaders,
    is_unique: false,
    is_optional: true,
  },
];
