import { FC } from "react";
import { Modal, Badge } from "../../lib";
import { ClientIcon } from "../../lib/icons/client";

type Props = {
  isOpen: boolean;
  toggleModal: () => void;
  clients: any[];
  selectClient: (client_id: number) => void;
};

const SwitchClient: FC<Props> = ({
  isOpen,
  toggleModal,
  clients = [],
  selectClient,
}: Props) => {
  const close = () => {
    toggleModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title="Choose Client"
      subTitle="Client"
      icon={ClientIcon}
    >
      <div className="">
        {clients.map((client: any, index: number) => (
          <li
            key={client.id}
            onClick={() => selectClient(client.id)}
            className="list-none p-4 border border-gray-300 rounded-md hover:bg-gray-200 cursor-pointer shadow-sm flex flex-row items-center mb-4 justify-between"
          >
            <div className="flex flex-row gap-2 items-center">
              <span className="font-bold text-gray-700 text-sm lg:text-base">
                {client.name}
              </span>
            </div>
            {index === 0 ? (
              <Badge color="blue" label="Current" isRounded={true} />
            ) : null}
          </li>
        ))}
      </div>
    </Modal>
  );
};

export default SwitchClient;
