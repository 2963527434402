import { FC } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  classes?: string;
};

const BackButton: FC<Props> = ({ classes }: Props) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <button
      onClick={() => navigate(-1)}
      id="test-back-button"
      className="text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 shadow items-center px-4 py-2 border rounded-md shadow-sm back-button justify-center"
    >
      <div className="flex justify-center items-center py-1">
        {i18n.language == "ar" ? (
          <ArrowRightIcon className="" height={20} width={20} />
        ) : (
          <ArrowLeftIcon className="" height={20} width={20} />
        )}
      </div>
    </button>
  );
};

export default BackButton;
