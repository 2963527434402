import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../layout/loading-spinner";
import { Button, Errors, Input, Select } from "../../lib";
import { AppStore } from "../../lib/icons/app-store";
import { Modal } from "../../lib/modal";
import { eventReadableName } from "../../pages/Webhooks";
import {
  createWebhookApi,
  removeWebhooksApi,
  updateWebhookApi,
} from "../../utils/api/webhooks-apis";

export interface EventInterface {
  id: string;
  name: string;
}

export interface WebhookInterface {
  event_id: string;
  method: string;
  url: string;
  id?: string;
}

const methods = [
  { method: "get" },
  { method: "post" },
  { method: "put" },
  { method: "patch" },
  { method: "delete" },
];

type Props = {
  toggleModal: () => void;
  isOpen: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  webhook: WebhookInterface;
  setWebhook: React.Dispatch<React.SetStateAction<WebhookInterface>>;
  events: EventInterface[]; // FIXME
};

const ManageWebhookModal = ({
  toggleModal,
  isOpen,
  setRefresh,
  webhook,
  setWebhook,
  events = [],
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [createWebhookErrors, setCreateWebhookErrors] = useState<any>({});
  const [initialWebhookState, setInitialWebhookState] = useState({
    event_id: "",
    method: "post",
    url: "",
  });

  const close = () => {
    toggleModal();
    setCreateWebhookErrors({});
    setWebhook(initialWebhookState);
  };

  const { t, i18n } = useTranslation();

  const handleCreateWebhook = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      webhook.id
        ? await updateWebhookApi(webhook)
        : await createWebhookApi(webhook);
      setRefresh((prev) => !prev);
      setIsLoading(false);
      close();
    } catch (e: any) {
      e.error ? setCreateWebhookErrors(e) : void 0;
      e.message ? setCreateWebhookErrors(e.message) : void 0;
      console.log(createWebhookErrors?.url);
      setIsLoading(false);
    }
  };

  const handleRemoveWebhook = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await removeWebhooksApi(webhook.id as string);
      setRefresh((prev) => !prev);
      setIsLoading(false);
      close();
    } catch (e: any) {
      e.error ? setCreateWebhookErrors(e) : void 0;
      e.message ? setCreateWebhookErrors(e.message) : void 0;
      setIsLoading(false);
    }
  };

  const updateValue = (val: string, key: string) => {
    setWebhook((prevState: WebhookInterface) => ({
      ...prevState,
      [key]: val,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("webhooks")}
      subTitle={webhook?.id ? t("editWebhook") : t("createWebhook")}
      close={close}
      icon={AppStore}
    >
      <LoadingSpinner isLoading={isLoading} isTransparent={true} />
      <form className="flex flex-col gap-2">
        <Errors
          errors={
            createWebhookErrors.error
              ? [
                  i18n.language == "ar"
                    ? createWebhookErrors.error.arabic
                    : createWebhookErrors.error.english,
                ]
              : []
          }
        />

        <Select
          name="event"
          valueKey="id"
          value={webhook.event_id}
          onChange={(e) => updateValue(e, "event_id")}
          label={"Event"}
          data={events.map((i) => ({ ...i, name: eventReadableName(i.name) }))}
          displayKey={"name"}
          placeholder=""
          isDisabled={webhook.id ? true : false}
          errors={createWebhookErrors?.event_id || []}
        />

        <Select
          name="method"
          valueKey="method"
          value={webhook.method}
          onChange={(e) => updateValue(e, "method")}
          label={"Method"}
          data={methods}
          displayKey={"method"}
          selectClasses={"uppercase"}
          errors={createWebhookErrors?.method || []}
        />
        <Input
          type="text"
          label={t("Url") as string}
          value={webhook.url}
          name="url"
          placeholder="https://example.com/event.."
          onChange={(e) => updateValue(e, "url")}
          errors={createWebhookErrors?.url || []}
        />

        <div className="flex justify-between items-center gap-2">
          <Button
            classNames=""
            onClick={handleCreateWebhook}
            label={
              webhook.id
                ? (t("save") as string)
                : (t("createWebhook") as string)
            }
            colorScheme={"black"}
            className={"px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"}
            isRounded={false}
          />

          {webhook.id ? (
            <button
              onClick={handleRemoveWebhook}
              className="px-4 py-1 lg:py-2 text-xs lg:text-sm rounded-md text-red-600 bg-red-200 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-100 border border-red-200 shadow"
            >
              Remove
            </button>
          ) : (
            ""
          )}
        </div>
      </form>
    </Modal>
  );
};

export default ManageWebhookModal;
