import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  EmptyState,
  Invite,
  MerchantsListItem,
  Switch,
  TerminalIcon,
  UserListItem,
} from "../../lib";
import ListTerminalConnections from "../terminal-connections/list";
import { Badge, BadgeType } from "../../lib/shared/badge";
import {
  disconnectTerminalApi,
  fileDownloadApi,
  findTerminalApi,
  toggleAutoReconcileApi,
  unAssginTerminalApi,
} from "../../utils/api/terminals-apis";
import AssignTerminalToUserModal from "../modal/assign-terminal-to-user";
import ListReconciliations from "../reconciliations/list";
import ListTransactions from "../transactions/list";
import moment from "moment";
import { ReactComponent as ReconcileIcon } from "../../assets/reconcile.svg";
import { reconcileApi } from "../../utils/api/reconcile-apis";
import { ReactComponent as FileDownloadIcon } from "../../assets/file-download.svg";
import ManageTerminals from "../modal/manage-terminals";

type Props = {
  id: string;
  isLoading: boolean;
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
};

const ViewTerminal = ({ id, setIsLoading, isLoading }: Props) => {
  const [terminal, setTerminal] = useState<{ [key: string]: any }>({});

  const [refreshTerminal, setRefreshTerminal] = useState(false);
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const [refreshReconciliations, setRefreshReconciliations] = useState(false);
  const [isAssignToUserModalOpen, setIsAssignToUserModal] = useState(false);
  const [reconciliationsIsLoading, setReconciliationsIsLoading] =
    useState(true);
  const [transactionsIsLoading, setTransactionsIsLoading] = useState(true);
  const [showTerminalsModal, setShowTerminalsModal] = useState(false);
  const { t } = useTranslation();

  const toggleAssignUserModal = () => {
    setIsAssignToUserModal((prevState: boolean) => !prevState);
  };

  const toggleModal = () => {
    setShowTerminalsModal((prevState: boolean) => !prevState);
  };

  const disconnectDevice = async (device_id: string) => {
    if (window.confirm("Are you sure, you want to remove this connection?")) {
      await disconnectTerminalApi({
        terminal_id: terminal.id,
        device_id,
      });
      return true;
    }
    return false;
  };

  const UnAssignTerminal = async () => {
    if (window.confirm("Are you sure, you want to remove access ?")) {
      await unAssginTerminalApi({
        id: terminal.id,
      });
      setRefreshTerminal((prevState) => !prevState);
    }
  };

  const reconcile = async ({
    terminal_id,
    merchant_id,
  }: {
    terminal_id: string;
    merchant_id: string;
  }) => {
    try {
      await reconcileApi({ terminal_id, merchant_id });
      setRefreshReconciliations((prev) => !prev);
      setRefreshTransactions((prev) => !prev);
    } catch (error) {
      console.log({ error });
    }
  };

  const toggleAutoReconcile = async (isEnabled: boolean) => {
    try {
      await toggleAutoReconcileApi({
        terminal_id: terminal.id,
        isEnabled,
      });
      await findTerminal();
    } catch (e) {
      console.log({ e });
    }
  };

  const fileDownload = async (isFull: boolean) => {
    setIsLoading(true);
    await fileDownloadApi({ id: terminal.id, isFull });
    setRefreshTerminal((prev) => !prev);
  };

  const findTerminal = async () => {
    try {
      setIsLoading(true);
      const { terminal } = await findTerminalApi({ id: id as string });
      setTerminal(terminal);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(() => false);
  }, [terminal]);

  useEffect(() => {
    findTerminal();
  }, [refreshTerminal]);

  const isConnectedBadge: BadgeType = terminal.is_connected
    ? { label: t("deviceConnected"), color: "gray" }
    : { label: t("deviceNotConnected"), color: "green" };
  const isRegisteredBadge: BadgeType = terminal.is_assigned_to_user
    ? { label: t("terminalAssinged"), color: "gray" }
    : { label: t("terminalNotAssinged"), color: "green" };

  return (
    <>
      {/* this is container */}
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="lg:text-3xl text-xl font-semibold">{t("info")}</div>
          <div>
            {" "}
            <Button
              onClick={() => {
                toggleModal();
              }}
              colorScheme="black"
              label={t("edit") as string}
              className="p-2"
            />
          </div>
        </div>

        <div className="flex justify-between my-4 ">
          {/* left  */}
          <div className="flex ">
            <div className="">
              <TerminalIcon />
            </div>
            <div className="flex flex-col">
              <div className="text-sm">{t("terminalsIdentifier")}</div>
              <div className="font-bold lg:text-lg text-base">
                {"#" + terminal?.tid}
              </div>
            </div>
          </div>
          {/* right  */}
          <div className="flex flex-col lg:flex-row items-center gap-4 justify-around lg:flex-initial lg:mt-0 ">
            <div className="flex flex-row items-center justify-center space-x-2">
              <span className="font-medium text-gray-500">Auto Reconcile</span>
              <Switch
                isEnabled={terminal.auto_reconcile}
                setIsEnabled={toggleAutoReconcile}
              />
            </div>
            <Badge {...isRegisteredBadge} isRounded={true} />
            <Badge {...isConnectedBadge} isRounded={true} />
          </div>
        </div>
        <div className="w-full flex flex-col">
          <div className="font-semibold mt-3">{t("merchant")}</div>
          {terminal?.merchant?.id ? (
            <MerchantsListItem role={"client"} item={terminal?.merchant} />
          ) : (
            <EmptyState title={t("itemNotFound")} />
          )}
        </div>
        <div className="flex flex-col">
          <div className="text-xl lg:text-3xl font-semibold mt-4">
            {t("access")}
          </div>
          <div className="lg:flex grid grid-cols-1 gap-4 ">
            <div className="flex flex-col flex-1 mt-3">
              {terminal.user?.name ? (
                <UserListItem
                  title={t("user")}
                  role={"client"}
                  item={terminal.user}
                  assignToUser={toggleAssignUserModal}
                  actionButtonOnClick={UnAssignTerminal}
                />
              ) : (
                <EmptyState
                  componentTitle={t("user")}
                  component={
                    <div className="">
                      <Button
                        colorScheme={"black"}
                        size={"sm"}
                        className={"py-1 text-xs px-2 lg:text-sm w-full"}
                        label={
                          <div className="flex items-center justify-between px-1">
                            <div>
                              <Invite
                                color="fill-white"
                                size={18}
                                containerClassNames="!p-1"
                              />
                            </div>
                            <div className="text-sm block">
                              {t("inviteUser")}
                            </div>
                          </div>
                        }
                        onClick={toggleAssignUserModal}
                      />
                    </div>
                  }
                />
              )}
            </div>

            <div className="flex flex-col flex-1 mt-3">
              <span className="text-lg font-bold text-gray-600">
                Connections
              </span>
              {terminal.id ? (
                <ListTerminalConnections
                  terminal_id={terminal.id}
                  setIsLoading={setIsLoading}
                  limit={5}
                  disconnectTerminal={disconnectDevice}
                />
              ) : (
                <EmptyState title={t("itemNotFound")} />
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:flex flex-col justify-between gap-4 mt-4">
            <div className="flex flex-col flex-1 details">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
                <div className="flex justify-between">
                  <div>{t("transactions")}</div>
                  <div className="max-w-fit">
                    <Button
                      colorScheme={"default"}
                      size={"default"}
                      className={"px-4 py-1 text-xs lg:text-sm w-full"}
                      label={
                        <div className="flex items-center gap-1">
                          <div>{<ReconcileIcon color={"black"} />}</div>
                          <span className="text-sm block">
                            {t("reconcile")}
                          </span>
                        </div>
                      }
                      onClick={() =>
                        reconcile({
                          terminal_id: terminal?.reference_terminal_id,
                          merchant_id: terminal?.merchant?.id,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {terminal?.reference_terminal_id && (
                <ListTransactions
                  terminal_id={terminal?.reference_terminal_id}
                  limit={5}
                  refresh={refreshTransactions}
                  setIsLoading={setTransactionsIsLoading}
                />
              )}
            </div>
            <div className="flex flex-col flex-1">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
                <div className="flex justify-between">
                  <div>{t("reconciliations")}</div>
                </div>
              </div>
              {terminal?.reference_terminal_id && (
                <ListReconciliations
                  terminal_id={terminal?.reference_terminal_id}
                  limit={5}
                  refresh={refreshReconciliations}
                  setRefresh={setRefreshReconciliations}
                  setIsLoading={setReconciliationsIsLoading}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col flex-1 mt-3">
            <div className="flex justify-between items-center mb-2 gap-3">
              <div className="text-xl lg:text-3xl lg:p-2 lg:pl-1 font-bold ">
                {t("profile")}
              </div>
              <div className="flex gap-2">
                <div>
                  <Button
                    colorScheme={"skyblue"}
                    className={
                      "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"
                    }
                    label={
                      <div className="flex items-center gap-2">
                        <FileDownloadIcon />
                        <div className="flex-auto">
                          {t("partialFileDownload")}
                        </div>
                      </div>
                    }
                    onClick={() => fileDownload(false)}
                  ></Button>
                </div>
                <div>
                  <Button
                    colorScheme={"lightYellow"}
                    className={
                      "px-4 py-1 text-xs lg:px-4 lg:py-2 lg:text-sm w-full"
                    }
                    label={
                      <div className="flex items-center gap-2">
                        <FileDownloadIcon />
                        <div className="flex-auto">{t("fullFileDownload")}</div>
                      </div>
                    }
                    onClick={() => fileDownload(true)}
                  ></Button>
                </div>
              </div>
            </div>
            {terminal?.profile?.id ? (
              <Card>
                <div className="flex justify-between items-center ltr">
                  <div className="flex gap-10">
                    <div className="flex flex-col">
                      <div>{t("profileDate")}</div>
                      <div className="flex gap-2">
                        <div>
                          {moment(terminal.profile.updated_at).format(
                            "YYYY-MM-DD"
                          )}
                          <b>
                            {" "}
                            {moment(terminal.profile.updated_at).format(
                              "hh:mm:ss A"
                            )}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div>{t("createdBy")}</div>
                      <div className="font-bold">Host</div>
                    </div>
                  </div>
                  {/* <div className="rounded bg-blue-500 p-2 text-white">
                    {t("current")}
                  </div> */}
                </div>
              </Card>
            ) : (
              <EmptyState title={t("itemNotFound")} />
            )}
          </div>
        </div>
      </div>
      {terminal?.id ? (
        <>
          <AssignTerminalToUserModal
            isOpen={isAssignToUserModalOpen}
            toggleModal={toggleAssignUserModal}
            terminalId={terminal?.id}
            refresh={() => setRefreshTerminal(!refreshTerminal)}
            merchant_id={terminal.merchant.id}
          />
          <ManageTerminals
            isOpen={showTerminalsModal}
            toggleModal={toggleModal}
            terminal={{
              id: terminal.id,
              merchant_category_code: terminal.merchant_category_code,
              receipt_address: terminal.host_related_data.merchant_address_1,
              card_acceptor_name_and_location:
                terminal.host_related_data?.card_acceptor_name_and_location,
              reference_terminal_id: terminal.reference_terminal_id,
            }}
            refresh={() =>
              setRefreshTerminal((prevState: boolean) => !prevState)
            }
          />
        </>
      ) : null}
    </>
  );
};

export default ViewTerminal;
